import * as React from 'react'
import { useState } from 'react'
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  InputGroup,
  InputRightAddon,
  Input,
} from '@chakra-ui/react'
import { Values, iSlider } from './types'

export const UseSliders: React.FC<iSlider> = ({
  values,
  min,
  max,
  step,
  handleChangeSliderValues,
  index,
}) => {
  const [value, setValue] = useState<Values>(values)

  React.useEffect(() => {
    if (value.final > 100) setValue({ initial: 0, final: 100 })
  }, [value])

  return (
    <Box w={'30rem'} display={'flex'} justifyContent={'space-between'}>
      <Box w={'10rem'}>
        <RangeSlider
          aria-label={['min', 'max']}
          defaultValue={[values.initial, values.final]}
          value={[values.initial, values.final]}
          min={min ?? 0}
          max={max ?? 100}
          step={step}
          onChange={(val) => {
            handleChangeSliderValues(
              { initial: val[0], final: val[1], level: values.level },
              index ?? 0
            )
          }}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0}></RangeSliderThumb>
          <RangeSliderThumb index={1}></RangeSliderThumb>
        </RangeSlider>
      </Box>
      <Box w={'8rem'} marginLeft={5} marginTop={-3}>
        <InputGroup size="sm">
          <Input
            type="number"
            max={100}
            // placeholder="0"
            value={values?.initial}
            onChange={(e) =>
              handleChangeSliderValues(
                {
                  initial:
                    Number(e.target.value) > 100
                      ? 100
                      : Number(e.target.value) > values?.final
                      ? values?.final
                      : Number(e.target.value) > 100
                      ? 100
                      : Number(e.target.value),
                  final: values?.final,
                  level: values.level,
                },
                index ?? 0
              )
            }
          />
          <InputRightAddon>%</InputRightAddon>
        </InputGroup>
      </Box>
      <Box w={'8rem'} marginLeft={5} marginTop={-3}>
        <InputGroup size="sm">
          <Input
            type="number"
            max={100}
            // placeholder="0"
            value={values?.final}
            onChange={(e) =>
              handleChangeSliderValues(
                {
                  initial: values.initial,
                  final:
                    Number(e.target.value) > 100 ? 100 : Number(e.target.value),
                  level: values.level,
                },
                index ?? 0
              )
            }
          />
          <InputRightAddon>%</InputRightAddon>
        </InputGroup>
      </Box>
    </Box>
  )
}
