import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Checkbox, Tooltip } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Paper from 'components/Paper'
import Status from 'components/Status'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'
import { getApprovalRequestListService } from '../Service/get-approval-request-list.service'
import { ViewSvg } from './styles'

const limit = 15

interface IRequestsTable {
  handleClickRow: (id: number) => void
  ids: number[]
}

const Table: React.FC<IRequestsTable> = ({ handleClickRow, ids }) => {
  const navigate = useNavigate()

  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const viewDetails = useCallback(
    (id: string, type: string, status: string) => {
      let url =
        type === 'DISCOUNT'
          ? `/approval/discount/${id}`
          : `/approval/table/${id}`

      if (/aguardando/i.test(status)) {
        url += '/edit'
      } else {
        url += '/view'
      }

      navigate(url)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: ({ row }: any) => (
          <Checkbox
            isChecked={ids.includes(row.id)}
            onChange={() => handleClickRow(row.id)}
          />
        ),
      },
      { Header: 'ID', accessor: 'id' },
      { Header: 'Solicitante', accessor: 'creator_name' },
      { Header: 'Nome do Cenário', accessor: 'scenery_name' },
      {
        Header: 'Tipo de Preço',
        accessor: ({ row }: any) => (
          <>{row.scenery.type === 'DISCOUNT' ? 'Desconto' : 'Tabela'}</>
        ),
      },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <>{moment(row.updated_at).zone(0).format('DD/MM/YYYY HH:mm')}</>
        ),
      },
      {
        Header: 'Status',
        accessor: ({ row }: any) => <Status status={row.status} />,
      },
      {
        Header: 'Visualizar',
        accessor: ({ row }: any) => (
          <Tooltip label="Ver cenário" placement="top">
            <ViewSvg
              onClick={() => viewDetails(row.id, row.scenery.type, row.status)}
            />
          </Tooltip>
        ),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids])

  const getList = useCallback(async () => {
    await getApprovalRequestListService(page, limit).then((res: any) => {
      setRows(res.payload)
      setTotal(res.total)
      setTotalPage(res.totalPages)
      setLoading(false)
    })
  }, [page])

  useEffect(() => {
    getList()
  }, [getList])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
      />
    </Paper>
  )
}

export default Table
