import { HStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'

import Card from './Card'
import IData from './types/IData'

const Cards: React.FC = () => {
  const { id } = useParams()

  const [data, setData] = useState<IData[]>([])

  const getData = useCallback(() => {
    api.get(`/requests/${id}/big-numbers`).then((res) => {
      const info = res.data.data.big_numbers[0]

      setData([
        {
          title: 'Preço Médio Histórico',
          main: info.preco_medio,
          volume: info.preco_medio_volume,
          faturamento: info.preco_medio_faturamento_bruto,
          margem1: info.preco_medio_margem_contribuicao_rs,
          margem2: info.preco_medio_margem_contribuicao_porce,
          margem3: info.preco_medio_margem_bruta_rs,
          margem4: info.preco_medio_margem_bruta_porce,
          color: 'green',
        },
        {
          title: 'Preço Médio Atual',
          main: info.preco_medio_atual,
          volume: info.preco_medio_atual_volume,
          faturamento: info.preco_medio_atual_faturamento_bruto,
          margem1: info.preco_medio_atual_margem_contribuicao_rs,
          margem2: info.preco_medio_atual_margem_contribuicao_porce,
          margem3: info.preco_medio_atual_margem_bruta_rs,
          margem4: info.preco_medio_atual_margem_bruta_porce,
          color: 'purple',
        },
        {
          title: 'Preço Médio Simulado',
          main: info.preco_medio_simulado,
          volume: info.preco_medio_simulado_volume,
          faturamento: info.preco_medio_simulado_faturamento_bruto,
          margem1: info.preco_medio_simulado_margem_contribuicao_rs,
          margem2: info.preco_medio_simulado_margem_contribuicao_porce,
          margem3: info.preco_medio_simulado_margem_bruta_rs,
          margem4: info.preco_medio_simulado_margem_bruta_porce,
          indice: info.preco_medio_simulado_indice,
          color: 'yellow',
        },
        {
          title: 'Preço Médio Final',
          main: info.preco_medio_final,
          volume: info.preco_medio_final_volume,
          faturamento: info.preco_medio_final_faturamento_bruto,
          margem1: info.preco_medio_final_margem_contribuicao_rs,
          margem2: info.preco_medio_final_margem_contribuicao_porce,
          margem3: info.preco_medio_final_margem_bruta_rs,
          margem4: info.preco_medio_final_margem_bruta_porce,
          indice: info.preco_medio_final_indice,
          color: 'blue',
        },
      ])
    })
  }, [id])
  useEffect(getData, [getData])

  return (
    <HStack w="100%" spacing="1rem" mb="1rem">
      {data.map((info) => (
        <Card key={info.title} info={info} />
      ))}
    </HStack>
  )
}

export default Cards
