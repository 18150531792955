import styled from 'styled-components'

export const Error = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: red;

  svg {
    font-size: 18px;
    margin-right: 1ch;
  }
`
