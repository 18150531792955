export const labelDict: { [key: string]: string } = {
  client: 'Cliente',
  channel: 'Canal',
  cluster: 'Cluster',
  client_name: 'Cliente',
  channel_name: 'Canal',
  cluster_name: 'Cluster',
  nivel01: 'Nível 1',
  nivel02: 'Nível 2',
  nivel03: 'Nível 3',
  nivel04: 'Nível 4',
  nivel05: 'Nível 5',
}
