import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Checkbox as ChakraCheckbox, HStack } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: { [key: string]: any }
  updateCell: (accessor: string, newValue: any) => void
  setAllChecked: React.Dispatch<React.SetStateAction<string>>
}

const CustomIcon = ({ isChecked }: any) => {
  return <>{isChecked ? 'X' : null}</>
}

export const Checkbox: React.FC<IProps> = ({
  row,
  updateCell,
  setAllChecked,
}) => {
  const { id } = useParams()

  const [checked, setChecked] = useState(row.status || '-')

  const onChange = useCallback(
    (newVal: string) => {
      setChecked(newVal)
      updateCell('status', newVal)

      const url = `/requests/${id}/items/${row.id}/status`
      const req = { status: newVal }

      api.put(url, req)
    },
    [id, row.id, updateCell]
  )

  return (
    <HStack>
      <ChakraCheckbox
        size="lg"
        colorScheme="green"
        isChecked={checked === 'S'}
        onChange={() => onChange(checked === 'S' ? '-' : 'S')}
      />
      <ChakraCheckbox
        size="lg"
        colorScheme="red"
        icon={<CustomIcon />}
        isChecked={checked === 'N'}
        onChange={() => onChange(checked === 'N' ? '-' : 'N')}
      />
    </HStack>
  )
}

export default Checkbox
