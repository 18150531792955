import { useCallback, useEffect, useState, useReducer, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import { Divider, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react'

import Modal from 'components/Modal'
import Button from 'components/Button'

import IFilters from '../../types/IFilters'

import nloop from './nloop'
import Niveis from './Niveis'
import FilterAutoComplete from './FilterAutoComplete'
import { handleGetOptions } from './actions'
import { niveisIniState, niveisReducer } from './reducer'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
}

const FilterButton: React.FC<IProps> = ({ filters, setFilters }) => {
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [client, setClient] = useState<IOption[]>([])
  const [clientOpt, setClientOpt] = useState<IOption[]>([])
  const [channel, setChannel] = useState<IOption[]>([])
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])
  const [clusterId, setClusterId] = useState<IOption[]>([])
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [niveis, dispatch] = useReducer(niveisReducer, niveisIniState)
  const [isFiltering, setIsFiltering] = useState(false)

  const request = useMemo(() => {
    const newRequest: { [n: string]: string[] } = {}

    nloop.forEach((n) => {
      newRequest[`nivel0${n}`] = niveis[n].map((o) => o.value)
    })

    return newRequest
  }, [niveis])

  const getOptions = useCallback(() => {
    isOpen &&
      handleGetOptions(id!, request, (data: { [n: string]: IOption[] }) => {
        if (clientOpt.length === 0) {
          setClientOpt(data.client)
        }

        if (channelOpt.length === 0) {
          setChannelOpt(data.channel)
        }

        if (clusterIdOpt.length === 0) {
          setClusterIdOpt(data.cluster)
        }

        dispatch({ type: 'SETOPTS', payload: data.niveis })
      })
  }, [
    channelOpt.length,
    clientOpt.length,
    clusterIdOpt.length,
    id,
    isOpen,
    request,
  ])
  useEffect(getOptions, [getOptions])

  const setNivel = useCallback((data: { [n: string]: IOption[] }) => {
    dispatch({ type: 'SETNIVEL', payload: data })
  }, [])

  const setDefaultData = useCallback(() => {
    nloop.forEach((n) => {
      setNivel({ [n]: filters[`nivel0${n}`] || [] })
    })

    setClient(filters.client || [])
    setChannel(filters.channel || [])
    setClusterId(filters.cluster_id || [])
  }, [filters, setNivel])
  useEffect(setDefaultData, [setDefaultData, isOpen])

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      channel: channel,
      cluster_id: clusterId,
      client_name: client,
    }

    nloop.forEach((n) => {
      newFilters[`nivel0${n}`] = niveis[n]
    })

    setIsFiltering(false)

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]?.length > 0) setIsFiltering(true)
    })

    setFilters(newFilters)

    onClose()
  }, [channel, client, clusterId, niveis, onClose, setFilters])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      uf: [],
      cluster_id: [],
      classificacao_produto: [],
      estrategia_posicionamento: [],
    }

    nloop.forEach((n) => {
      newFilters[`nivel0${n}`] = []
    })

    setIsFiltering(false)
    setFilters(newFilters)

    onClose()
  }, [onClose, setFilters])

  return (
    <>
      <Flex>
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        w="50rem"
        isOpen={isOpen}
        onClose={onClose}
        title="Filtros"
        body={
          <HStack align="flex-start" spacing="1rem">
            <VStack w="100%" align="flex-start">
              <Niveis niveis={niveis} setNivel={setNivel} />
            </VStack>
            <VStack w="100%" align="flex-start">
              <FilterAutoComplete
                label="Cluster"
                value={clusterId}
                options={clusterIdOpt}
                setValue={setClusterId}
              />
              <FilterAutoComplete
                label="Canais"
                value={channel}
                options={channelOpt}
                setValue={setChannel}
              />
              <FilterAutoComplete
                label="Clientes"
                value={client}
                options={clientOpt}
                setValue={setClient}
              />
            </VStack>
          </HStack>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Button onClick={onClear}>Limpar</Button>
              <Button onClick={onFilter}>Filtrar</Button>
            </Flex>
          </VStack>
        }
      />
    </>
  )
}

export default FilterButton
