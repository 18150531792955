const rolesDictionary = (name: string) => {
    return (
      {
        ADMIN: 'Admin',
        READ_PARAMETERS: 'Visualizar Parâmetros',
        WRITE_PARAMETERS: 'Editar Parâmetros',
        READ_SCENERY: 'Visualizar Simulador Preço Tabela',
        WRITE_SCENERY: 'Editar Simulador Preço Tabela',
        READ_DISCOUNT_SCENERY: 'Visualizar Simulador Preço Desconto',
        WRITE_DISCOUNT_SCENERY: 'Editar Simulador Preço Desconto',
      }[name] || name
    )
  }

  export default rolesDictionary