import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import api from '../../../services/api'
import Paper from '../../../components/Paper'
import Status from '../../../components/Status'
import TableBackend from '../../../components/TableBackend'
import LoadingScreen from '../../../components/LoadingScreen'

import { EditSvg } from './styles'

const limit = 15

const Table: React.FC = () => {
  const navigate = useNavigate()

  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPage] = useState<number>(1)

  const onEdit = useCallback(
    (row: any) => {
      const { id, type } = row

      const url =
        type === 'DISCOUNT'
          ? `/pricebase/discount/${id}/A`
          : `/pricebase/table/${id}/A`

      navigate(url)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Solicitante', accessor: 'user' },
      { Header: 'Nome do Cenário', accessor: 'name' },
      { Header: 'Tipo', accessor: 'type' },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <>{moment(row.created_at).zone(0).format('DD/MM/YYYY HH:mm')}</>
        ),
      },
      {
        Header: 'Status',
        accessor: ({ row }: any) => <Status status={row.status} />,
      },
      {
        Header: 'Editar',
        accessor: ({ row }: any) =>
          row.status === 'Editando' && <EditSvg onClick={() => onEdit(row)} />,
      },
    ]
  }, [onEdit])

  const getUsers = useCallback(() => {
    api.post(`/sceneries/?page=${page}&limit=${limit}`).then((res) => {
      setRows(
        res.data?.data?.map((row: any) => ({
          ...row,
          user: row.creator?.name,
          type: row.scenery?.type === 'DISCOUNT' ? 'Desconto' : 'Tabela',
        }))
      )
      setTotal(res.data?.data?.total)
      setTotalPage(res.data?.data?.totalPages)
      setLoading(false)
    })
  }, [page])

  useEffect(getUsers, [getUsers])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
      />
    </Paper>
  )
}

export default Table