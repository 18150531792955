import { extendTheme } from '@chakra-ui/react'

const chakraUITheme = extendTheme({
  shadows: {
    outline: 'none',
  },
  fontSizes: {
    xs: '1rem',
    sm: '1.125rem',
    md: '1.25rem',
    lg: '1.375rem',
    xl: '1.5rem',
    '2xl': '1.75rem',
    '3xl': '2.125rem',
    '4xl': '2.5rem',
    '5xl': '3.25rem',
    '6xl': '4rem',
    '7xl': '4.75rem',
    '8xl': '6.25rem',
    '9xl': '8.25rem',
  },
  color: {
    primary: {
      500: '#003b74',
    },
  },
})

export default chakraUITheme
