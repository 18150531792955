import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export const Background = styled.img`
  height: 100vh;
  object-fit: cover;
`
