import { useCallback } from 'react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: { [key: string]: any }
  updateCell: (accessor: string, newValue: any) => void
  toggleBigData: () => void
}

export const Checkbox: React.FC<IProps> = ({
  row,
  updateCell,
  toggleBigData,
}) => {
  const updateChecked = useCallback(
    (enabled: boolean) => {
      const url = '/sceneries/item'
      const req = { id: row.id, enabled }

      api.put(url, req).then(() => {
        toggleBigData()
      })
    },
    [row.id, toggleBigData]
  )

  const onChange = useCallback(() => {
    updateCell('enabled', !row.enabled)
    updateChecked(!row.enabled)
  }, [row.enabled, updateCell, updateChecked])

  return (
    <ChakraCheckbox size="lg" isChecked={row.enabled} onChange={onChange} />
  )
}

export default Checkbox
