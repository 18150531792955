import { useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  RiArrowUpSFill as OpenSvg,
  RiArrowDownSFill as ClosedSvg,
} from 'react-icons/ri'

import {
  Container,
  DropdownBtn,
  IconContainer,
  DropdownContent,
} from './styles'

interface IProps {
  width?: string
  children: Children
  dropdownText: string
}

const AppBarDropdown: React.FC<IProps> = ({
  width,
  children,
  dropdownText,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container onClick={() => setOpen(!open)}>
        <DropdownBtn width={width} open={open}>
          {dropdownText}
          <IconContainer>{open ? <OpenSvg /> : <ClosedSvg />}</IconContainer>
        </DropdownBtn>
        <DropdownContent open={open}>{children}</DropdownContent>
      </Container>
    </ClickAwayListener>
  )
}

export default AppBarDropdown
