import rolesDictionary from "./rolesDictionary"
const handleRoles = (roles: any) => {
    if (!Array.isArray(roles)) return ''
    if (roles.find((role) => /admin/i.test(role.name))) return 'Admin'
  
    return (
      roles
        .map((r: any) => rolesDictionary(r.name))
        .sort((a: any, b: any) => a.localeCompare(b))
        .join('; ') || ''
    )
  }

  export default handleRoles