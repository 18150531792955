import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../services/api'
import Paper from '../../../components/Paper'
import TableBig from '../../../components/TableBig'
import LoadingScreen from '../../../components/LoadingScreen'
import handleRoles from '../../../utils/handleRoles'

import { Regions, User } from './types'
import Status from './Status'
import { EditSvg } from './styles'
const Table: React.FC = () => {
  const navigate = useNavigate()
  const [rows, setRows] = useState(null)
  const [loading, setLoading] = useState(true)
  

  const editUser = useCallback(
    (id: string | number) => {
      navigate(`/users/edit/${id}`)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Acessos', accessor: 'roles' },
      { Header: 'Área de Atuação', accessor: 'regions' },
      {
        Header: 'Ativo',
        accessor: 'active',
        Cell: ({ row }: any) => <Status active={row.original.active} />,
      },
      {
        Header: 'Editar',
        accessor: 'edit',
        Cell: ({ row }: any) => (
          <EditSvg onClick={() => editUser(row.original.id)} />
        ),
      },
    ]
  }, [editUser])

  const getUsers = useCallback(() => {
    api.get('/access-controll/all').then((response) => {
      setRows(
        response.data?.map((user: User) => ({
          id: user?.id,
          name: user?.name,
          email: user?.email,
          active: user?.active,
          roles: handleRoles(user?.roles),
          regions: user?.regions?.map((r: Regions) => r.name).join('; ') || '',
        }))
      )
      setLoading(false)
    })
  }, [])

  useEffect(getUsers, [getUsers])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBig data={rows || []} columns={columns} />
    </Paper>
  )
}

export default Table
