import { useCallback, useState } from 'react'
import { Input } from '@chakra-ui/react'

import api from 'services/api'
import { moneyComma } from 'utils/masks'

import { formatData } from '../actions'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  updateRow: (newRow: { [key: string]: any }) => void
  quantVendaInputsRef: React.MutableRefObject<any[]>
  toggleBigData: () => void
}

export const QuantVenda: React.FC<IProps> = ({
  row,
  rowIndex,
  updateRow,
  quantVendaInputsRef,
  toggleBigData,
}) => {
  const [value, setValue] = useState(row.estoque)
  const [focusValue, setFocusValue] = useState(row.estoque)

  const onBlur = useCallback(() => {
    if (!value) return setValue(focusValue)
    if (value === row.estoque) return

    let enabled = true
    if (value === row.preco_liquido_unitario) enabled = false

    const url = '/discount-scenery/item'
    const req = { id: row.id, estoque: +value.replace(',', '.'), enabled }

    api.put(url, req).then((res) => {
      const formattedRow: any = formatData([{ ...res.data.data.scenery_item }])
      updateRow({ ...row, ...formattedRow[0], estoque: value, enabled })
      toggleBigData()
    })
  }, [focusValue, row, toggleBigData, updateRow, value])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(moneyComma(e.target.value, 0))
  }, [])

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') quantVendaInputsRef.current[rowIndex + 1].focus()
    },
    [rowIndex, quantVendaInputsRef]
  )

  return (
    <Input
      ref={(el) => (quantVendaInputsRef.current[rowIndex] = el)}
      size="sm"
      value={value}
      placeholder={focusValue}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onKeyPress={onKeyPress}
      textAlign="right"
    />
  )
}

export default QuantVenda
