import Table from './Table'
import Header from './Header'
import { Container } from './styles'

const PriceBase: React.FC = () => {
  return (
    <Container>
      <Header />
      <Table />
    </Container>
  )
}

export default PriceBase
