import { moneyComma } from 'utils/masks'

import Money from './Money'
import Percent from './Percent'
import Checkbox from './Checkbox'

export const cols = [
  {
    Header: '',
    accessor: ({ row, type, updateCell, toggleBigData }: any) =>
      type === 'edit' && (
        <Checkbox
          row={row}
          updateCell={updateCell}
          toggleBigData={toggleBigData}
        />
      ),
  },
  { Header: 'Cluster', accessor: 'cluster_name' },
  { Header: 'Canais', accessor: 'channel_name' },
  { Header: 'Cliente', accessor: 'client_name' },
  { Header: 'Cód. Capitão', accessor: 'sku_capitao' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Nível 1', accessor: 'nivel01' },
  { Header: 'Nível 2', accessor: 'nivel02' },
  { Header: 'Nível 3', accessor: 'nivel03' },
  {
    Header: 'Preço Tabela Atual',
    accessor: ({ row }: any) => <Money value={row.ppv_atual} />,
  },
  {
    Header: 'Custo Média Atual',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'Mrg Atual',
    accessor: ({ row }: any) => <Percent value={row.margem_atual} />,
  },
  {
    Header: 'Desconto',
    accessor: ({ row }: any) => <Percent value={row.desconto} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_novo} />,
  },
  { Header: 'Histórico Quant.', accessor: 'volume_historico' },
  {
    Header: 'Quant. Estimada Venda',
    accessor: ({ row }: any) => <>{moneyComma(row.estoque, 0)}</>,
  },
  {
    Header: 'Investimento',
    accessor: ({ row }: any) => <Money value={row.investimento} />,
  },
  {
    Header: 'Faturamento',
    accessor: ({ row }: any) => <Money value={row.faturamento} />,
  },
]
