import React from 'react'
import { Flex } from '@chakra-ui/react'

import { IProps } from './types'
import Cluster from './Cluster'
import Filters from './Filters'

const Options: React.FC<IProps> = ({
  level,
  setRequest,
  clusterId,
  setClusterId,
}) => {
  return (
    <Flex flexDir="column" w="100%" maxW="15rem" pl="1px">
      <Cluster setClusterId={setClusterId} />
      {level && clusterId && (
        <>
          <Filters level={level} setRequest={setRequest} />
        </>
      )}
    </Flex>
  )
}

export default Options
