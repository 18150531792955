import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { Roles, DefaultInfo } from './types'
import api from '../../../../services/api'
import { Values } from '../Access/Approvals/UseSlider/types'
import { sliderInitialValues } from 'pages/UsersRegister/Types/initalValues'

const useEdit = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { userId } = useParams<string>()

  const [name, setName] = useState<string>('')
  const [roles, setRoles] = useState<Roles[]>([])
  const [nivel, setNivel] = useState<number>(1)
  const [email, setEmail] = useState<string>('')
  const [access, setAccess] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  const [enrollment, setEnrollment] = useState<string>('')
  const [defaultInfo, setDefaultInfo] = useState<DefaultInfo>()
  const [loading, setLoading] = useState(true)
  const [clusters, setClusters] = useState<IOption[]>([])
  const [approvalScopes, setApprovalScopes] =
    useState<Values[]>(sliderInitialValues)

  const handleChangeSliderValues = (values: Values, index: number) => {
    const updatedValues = [...approvalScopes]
    updatedValues[index] = values
    setApprovalScopes(updatedValues)
  }

  useEffect(() => {
    if (nivel > approvalScopes.length) {
      const newScopes = Array.from(
        { length: nivel - approvalScopes.length },
        () => ({ initial: 0, final: 20, level: nivel })
      )
      setApprovalScopes((prevScopes) => [...prevScopes, ...newScopes])
    }

    if (nivel < approvalScopes.length) {
      setApprovalScopes((prevScopes) => prevScopes.slice(0, nivel))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nivel])

  useEffect(() => {
    setRoles((current) => {
      if (access) {
        return [...current, { id: 1, name: 'ADMIN' }]
      }

      return current.filter((role) => role.name !== 'ADMIN')
    })
  }, [access])

  const getUser = useCallback((userId: string) => {
    api.get(`/access-controll/${userId}`).then((response) => {
      const { user } = response.data?.data || {}

      setApprovalScopes(
        user?.approval_scope?.map((item: string) => JSON.parse(item))
      )
      setClusters(user?.clusters?.map((item: number) => String(item)))
      setName(user?.name)
      setEmail(user?.email)
      setNivel(+user?.approval_levels?.at(-1)?.title?.split('0')[1] || 1)
      setRoles(user?.roles.map((item: string) => JSON.parse(item)))
      setActive(user?.active)
      setEnrollment(user?.enrollment)
      setDefaultInfo({
        name: user?.name,
        email: user?.email,
        enrollment: user?.enrollment,
      })
      setAccess(user?.access)

      setLoading(false)
    })
  }, [])
  useEffect(() => {
    if (userId) {
      getUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const onCancel = useCallback(() => {
    navigate('/users')
  }, [navigate])

  const onSave = () => {
    const url = `/access-controll/${userId}`
    const request = {
      approvalScopes,
      name,
      email,
      roles,
      nivel: +nivel,
      access,
      enrollment,
      active: +active,
      clusters: clusters.map((item: IOption) => item.value),
    }

    return api
      .put(url, request)
      .then(() => {
        navigate('/users')
        toast({
          title: 'Usuário editado com sucesso!',
          status: 'success',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
        })
      })
  }

  return {
    onSave,
    onCancel,
    name,
    email,
    nivel,
    roles,
    access,
    active,
    enrollment,
    setName,
    setEmail,
    setNivel,
    setRoles,
    setAccess,
    setActive,
    setEnrollment,
    defaultInfo,
    loading,
    clusters,
    setClusters,
    approvalScopes,
    handleChangeSliderValues,
  }
}

export default useEdit
