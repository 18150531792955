import { useCallback, useEffect, useState } from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Flex } from '@chakra-ui/react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import api from 'services/api'

ChartJS.register(ChartDataLabels, ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    datalabels: {
      formatter: (value: any) => '',
    },
  },
}

const defaultData = {
  labels: ['Aprovado', 'Reprovado', 'Aguardando Aprovação'],
  datasets: [
    {
      data: [0, 0, 0],
      borderColor: ['#48BB78', '#F56565', '#ECC94B'],
      backgroundColor: ['#9AE6B4', '#FEB2B2', '#FEFCBF'],
      borderWidth: 1,
    },
  ],
}

const Graph: React.FC = () => {
  const [data, setData] = useState(defaultData)
  const [ready, setReady] = useState(false)

  const getData = useCallback(() => {
    console.log('Entender o porque disso')
    // api.get('/requests/status').then((res) => {
    //   setData((current) => {
    //     const values = current.datasets[0].data

    //     res.data.data.forEach((set: any) => {
    //       let index = 0

    //       if (/reprovado/i.test(set.status)) index = 1
    //       if (/aguardando/i.test(set.status)) index = 2

    //       values[index] = +set.total
    //     })

    //     current.datasets[0].data = values

    //     return current
    //   })
    //   setReady(true)
    // })
  }, [])
  useEffect(getData, [getData])

  if (!ready) return null
  return (
    <Flex w="20rem" m="auto 0">
      <Doughnut data={data} options={options} />
    </Flex>
  )
}

export default Graph
