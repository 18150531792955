import api from 'services/api'

import nloop from './nloop'

type THandleGetOptions = (
  id: string,
  req: { [n: string]: string[] },
  cb: (data: { [n: string]: IOption[] }) => void
) => void

export const handleGetOptions: THandleGetOptions = (id, req, cb) => {
  api.post(`/requests/${id}/items/filters`, req).then((res) => {
    const data = { ...res.data.data }

    data.cluster = data.cluster.map((cluster: any) => ({
      label: cluster.name,
      value: cluster.id,
    }))

    data.uf = data.uf.map((uf: string) => ({
      label: uf,
      value: uf,
    }))

    data.estrategia_posicionamento = data.estrategia_posicionamento.map(
      (strat: string) => ({
        label: strat,
        value: strat,
      })
    )

    data.classificacao_produto = data.classificacao_produto.map(
      (classficicao: string) => ({
        label: classficicao,
        value: classficicao,
      })
    )

    data.niveis = {}

    nloop.forEach((n) => {
      data.niveis[n] = data[`nivel0${n}`].map((nivel: string) => ({
        label: nivel,
        value: nivel,
      }))
    })

    cb(data)
  })
}
