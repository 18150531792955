import IRow from '../../types/IRow'

interface IProps {
  row: IRow
}

const MediaPond: React.FC<IProps> = ({ row }) => {
  if (!row.average) return null
  return <>{row.average}%</>
}

export default MediaPond
