import { HStack, Text, VStack, CheckboxGroup, Checkbox } from '@chakra-ui/react'
import { useCallback } from 'react'

const permissions = [
  {
    name: 'Parâmetros',
    read: {
      id: 2,
      name: 'READ_PARAMETERS',
    },
    write: {
      id: 3,
      name: 'WRITE_PARAMETERS',
    },
  },
  {
    name: 'Simulador Preço Tabela',
    read: {
      id: 4,
      name: 'READ_SCENERY',
    },
    write: {
      id: 5,
      name: 'WRITE_SCENERY',
    },
  },
  {
    name: 'Simulador Preço Desconto',
    read: {
      id: 6,
      name: 'READ_DISCOUNT_SCENERY',
    },
    write: {
      id: 7,
      name: 'WRITE_DISCOUNT_SCENERY',
    },
  },
]

interface IRole {
  id: number
  name: string
}

interface IProps {
  roles: IRole[]
  setRoles: React.Dispatch<React.SetStateAction<IRole[]>>
}

const Permissions: React.FC<IProps> = ({ roles, setRoles }) => {
  const isChecked = useCallback(
    (rol: IRole) => {
      return !!roles.find((role) => role.name === rol.name)
    },
    [roles]
  )

  const onChangeRead = useCallback(
    (rolRead: IRole, rolWrite: IRole) => {
      setRoles((current) => {
        if (roles.find((role) => role.name === rolRead.name)) {
          let newRoles = current.filter((role) => role.name !== rolRead.name)

          if (roles.find((role) => role.name === rolWrite.name)) {
            newRoles = newRoles.filter((role) => role.name !== rolWrite.name)
          }

          return newRoles
        }

        return [...current, rolRead]
      })
    },
    [roles, setRoles]
  )

  const onChangeWrite = useCallback(
    (rolRead: IRole, rolWrite: IRole) => {
      setRoles((current) => {
        if (roles.find((role) => role.name === rolWrite.name)) {
          return current.filter((role) => role.name !== rolWrite.name)
        }

        if (!roles.find((role) => role.name === rolRead.name)) {
          return [...current, rolWrite, rolRead]
        }

        return [...current, rolWrite]
      })
    },
    [roles, setRoles]
  )

  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Permissões
      </Text>
      {permissions.map((permission) => (
        <CheckboxGroup key={permission.name} size="lg">
          <HStack>
            <Checkbox
              isChecked={isChecked(permission.read)}
              onChange={() => onChangeRead(permission.read, permission.write)}
            >
              <Text fontSize="1rem">Visualizar</Text>
            </Checkbox>
            <Checkbox
              isChecked={isChecked(permission.write)}
              onChange={() => onChangeWrite(permission.read, permission.write)}
            >
              <Text fontSize="1rem">Editar</Text>
            </Checkbox>
          </HStack>
        </CheckboxGroup>
      ))}
    </VStack>
  )
}

export default Permissions
