import { memo } from 'react'
import { Grid, Text, VStack } from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'

import { labelDict } from './constants'
import Autocomplete from 'components/Autocomplete'

interface IProps {
  type: string
  options: { [key: string]: IOption[] }
  filters: { [key: string]: IOption[] }
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: IOption[] }>>
}

const Filters: React.FC<IProps> = ({ type, options, filters, setFilters }) => {
  if (type === 'TABELA') return null
  return (
    <Grid w="100%" templateColumns="repeat(2, 1fr)" gap="1rem">
      {Object.keys(options).map((key) => (
        <VStack key={uuid()} w="100%" align="flex-start">
          <Text>{labelDict[key]}</Text>
          <Autocomplete
            isMulti
            value={filters[key]}
            options={options[key]}
            setValue={(newVal: IOption[]) => {
              setFilters((current) => {
                return { ...current, [key]: newVal }
              })
            }}
          />
        </VStack>
      ))}
    </Grid>
  )
}

export default memo(Filters)
