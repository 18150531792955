import React, { useCallback, useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from '../../../../../../../../services/api'
import { Clients } from '../ViewButton/types'


interface IProps {
  bundle: Clients
}

const Contract: React.FC<IProps> = ({ bundle }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [contract, setContract] = useState<number>(bundle?.client?.contract)

  useEffect(() => {
    setTimeout(() => {
      setContract(bundle?.client?.contract)
      setLoading(false)
    }, 1000)
  }, [bundle])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setContract(parseInt(e.target.value, 10) || 0);
  }, [])
  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setContract(parseInt(e.target.value, 10) || 0);
      api.post(`/cluster-contracts/${bundle.client.code}/${contract}`)

    },
    [bundle.client.code, contract]
  )

  return (
    <>
      <InputGroup w="10rem" size="sm">
        <InputLeftAddon>%</InputLeftAddon>
        {loading ? (
          'Carregado...'
        ) : (
          <Input
            fontSize="1rem"
            maxW="7rem"
            type="number"
            name="contract"
            value={contract}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      </InputGroup>
    </>
  )
}

export default Contract
