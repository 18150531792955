import { useCallback, useRef } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  url: string
}

const ExportExcelButton: React.FC<IProps> = ({ url }) => {
  const toast = useToast()

  const linkRef = useRef<any>(null)
  const loadingRef = useRef<any>(null)

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise)
  }, [])

  const onClick = useCallback(() => {
    openLoading(
      'Gerando Excel...',
      api
        .post(url, { responseType: 'arraybuffer' })
        .then((res) => {
          const arrayBuffer = new Uint8Array(res.data.file.data).buffer;
  
          const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
  
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Tabela de Unidades Consumidoras');
  
          document.body.appendChild(link);
  
          link.click();
  
          document.body.removeChild(link);
        })
        .catch(() => {
          toast({
            title: 'Erro, tente novamente.',
            status: 'error',
            isClosable: true,
          });
        })
    );
  }, [openLoading, toast, url]);
  

  return (
    <>
      <Button onClick={onClick}>Exportar Excel</Button>
      <a ref={linkRef} style={{ display: 'none' }} download />
      <LoadingModal ref={loadingRef} />
    </>
  )
}

export default ExportExcelButton
