import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'
import IFilters from '../types/IFilters'
import { colsSimple } from './constants'

const limit = 17

interface IProps {
  filters: IFilters
}

const Table: React.FC<IProps> = ({ filters }) => {
  const { id, type } = useParams()

  const [rows, setRows] = useState<Record<string, unknown>[]>([])
  const [loading, setLoading] = useState(true)
  const [allChecked, setAllChecked] = useState('-')

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const cols = useMemo(() => {
    return colsSimple
  }, [])

  const inputsRef = useRef<any[]>([])

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, rows?.length)
  }, [rows?.length])

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [filters])

  const getRows = useCallback(() => {
    const url = `sceneries/${id}/items/A?page=${page}&limit=${limit}`

    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api.post(url, request).then((res) => {
      setRows(res.data.data.rows)
      setTotal(res.data.data.total)
      setTotalPage(res.data.data.totalPages)
      setLoading(false)
    })
  }, [filters, id, page])

  useEffect(() => {
    if (loading) getRows()
  }, [loading, getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
        stickyPos={8}
        cellProps={{
          type,
          filters,
          getRows,
          setRows,
          allChecked,
          setAllChecked,
        }}
      />
    </Paper>
  )
}

export default memo(Table)
