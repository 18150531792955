import * as React from 'react'
import { Box, Text, VStack } from '@chakra-ui/react'
import { UseSliders } from './UseSlider'
import { Values } from './UseSlider/types'

interface IProps {
  handleChangeSliderValues: (values: Values, index: number) => void
  sliderValues: Values[]
}

const Approvals: React.FC<IProps> = ({
  handleChangeSliderValues,
  sliderValues,
}) => {
  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Alçada de Aprovações
      </Text>

      {sliderValues?.map((n, index) => (
        <Box key={index}>
          <Text fontSize="1rem">Nível 0{index + 1}</Text>
          <UseSliders
            handleChangeSliderValues={handleChangeSliderValues}
            min={0}
            max={100}
            values={{ initial: n.initial, final: n.final, level: index + 1 }}
            step={0.1}
            index={index}
          />
        </Box>
      ))}
    </VStack>
  )
}

export default Approvals
