import { useState, useCallback, useContext } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { HiOutlineLogout } from 'react-icons/hi'
import {
  List,
  Drawer,
  ListItemText,
  AppBar as MUIAppBar,
} from '@material-ui/core'
import logoPricepoint from 'assets/logo-white.png'
import ListItems from './ListItems'
import AppBarDropdown from './AppBarDropdown'
import {
  LogoImg,
  Toolbar,
  BorderDiv,
  useStyles,
  DropdownItem,
  ToolbarWrapper,
} from './styles'
import { UserContext } from '../../../state/user-context'

const AppBar: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { clearUser, user } = useContext(UserContext)

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onSignOut = useCallback(() => {
    localStorage.clear()
    navigate('/login')
    clearUser()
  }, [navigate, clearUser])

  const logoOnClick = useCallback(() => {
    navigate('/dashboard')
  }, [navigate])

  return (
    <>
      <MUIAppBar className={clsx(classes.appBar)}>
        <Toolbar>
          <LogoImg src={logoPricepoint} onClick={logoOnClick} />
          <ToolbarWrapper>
            <AppBarDropdown
              width="16rem"
              dropdownText={user ? `Olá, ${user.name}` : 'Olá, Usuário!'}
            >
              <DropdownItem button onClick={onSignOut}>
                <HiOutlineLogout />
                <ListItemText primary="Sair" style={{ flex: 'none' }} />
              </DropdownItem>
            </AppBarDropdown>
          </ToolbarWrapper>
        </Toolbar>
      </MUIAppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.appBarSpacer} />
        <BorderDiv open={open} />
        <List onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
          <ListItems />
        </List>
      </Drawer>
    </>
  )
}

export default AppBar
