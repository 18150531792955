import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { useToast, Flex } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'

import IRounding from '../../types/IRounding'
import ArredondamentoInputs from 'pages/ParametersEdit/Header/ArredondamentoCreate/ArredondamentoInputs'

import { Container } from './styles'

interface IProps {
  row: IRounding
  isExpanded: boolean
}

const acceptedInitialState = (accepts: string): number[] => {
  return accepts.split(',').map((v) => +v)
}

const roundHouseInitialState = (rounding_precision: string): number => {
  return rounding_precision.length - rounding_precision.indexOf('X') - 1
}

const Panel: React.FC<IProps> = ({ row, isExpanded }) => {
  const toast = useToast()

  const [end, setEnd] = useState(row.to.toFixed(2) + '')
  const [start, setStart] = useState(row.from.toFixed(2) + '')
  const [round, setRound] = useState(row.rounding_method)
  const [example, setExample] = useState(row.example)
  const [accepted, setAccepted] = useState<number[]>(
    acceptedInitialState(row.accepts)
  )
  const [precision, setPrecision] = useState(row.rounding_precision)
  const [roundHouse, setRoundhouse] = useState<number>(
    roundHouseInitialState(row.rounding_precision)
  )

  const resetData = useCallback(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setEnd(row.to.toFixed(2) + '')
        setStart(row.from.toFixed(2) + '')
        setRound(row.rounding_method)
        setExample(row.example)
        setAccepted(acceptedInitialState(row.accepts))
        setPrecision(row.rounding_precision)
        setRoundhouse(roundHouseInitialState(row.rounding_precision))
      }, 250)
    }
  }, [
    isExpanded,
    row.accepts,
    row.example,
    row.from,
    row.rounding_method,
    row.rounding_precision,
    row.to,
  ])
  useEffect(resetData, [resetData])

  const adaptPrecision = useCallback(() => {
    const zeroedEnd = end.replace(/\d/g, '0')

    const adaptedPrecision = zeroedEnd
      .split('')
      .map((char, index) => {
        const revIndex = zeroedEnd.split('').length - index - 1
        return roundHouse === revIndex ? 'X' : char
      })
      .join('')

    return setPrecision(adaptedPrecision)
  }, [end, roundHouse])
  useEffect(adaptPrecision, [adaptPrecision])

  const onSave = useCallback(() => {
    if (!start) {
      return toast({
        title: 'Faixa inicial é obrigatória.',
        status: 'error',
        isClosable: true,
      })
    }

    if (!end) {
      return toast({
        title: 'Faixa final é obrigatória.',
        status: 'error',
        isClosable: true,
      })
    }

    if (+start > +end) {
      return toast({
        title: 'Faixa inicial deve ser menor que final.',
        status: 'error',
        isClosable: true,
      })
    }

    if (accepted.length === 0) {
      return toast({
        title: 'Rejeitar requer pelo menos 1 valor.',
        status: 'error',
        isClosable: true,
      })
    }

    const request = {
      to: +end,
      from: +start,
      example,
      ignores: _.times(9, (n) => n + 1)
        .filter((n) => !accepted.includes(n))
        .join(','),
      accepts: accepted.join(','),
      rounding_method: round,
      rounding_precision: precision,
    }

    api
      .put('/roundings', request)
      .then(() => {
        toast({
          title: 'Salvo com sucesso!',
          status: 'success',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [accepted, end, example, precision, round, start, toast])

  return (
    <Container>
      {JSON.stringify(row.accepts)}
      <Flex justifyContent="space-between">
        <ArredondamentoInputs
          end={end}
          setEnd={setEnd}
          start={start}
          setStart={setStart}
          round={round}
          setRound={setRound}
          setExample={setExample}
          accepted={accepted}
          setAccepted={setAccepted}
          roundHouse={roundHouse}
          setRoundhouse={setRoundhouse}
        />
        <Button onClick={onSave}>Salvar</Button>
      </Flex>
    </Container>
  )
}

export default Panel
