import { useEffect, useState } from 'react'
import { Box, Text, VStack, Table, Tbody, Tr, Td } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete'
import { Delete } from './styles'
import {
  ICluster,
  getClustersListService,
} from 'services/Clusters/get-clusters-list-service'

interface IProps {
  setClusters: React.Dispatch<React.SetStateAction<IOption[]>>
  clusters: IOption[]
}

const Cluster: React.FC<IProps> = ({ setClusters, clusters }) => {
  const [clusterSelected, setClusterSelected] = useState<IOption[]>([])
  const [data, setData] = useState<IOption[]>([])
  const [options, setOptions] = useState<IOption[]>([])

  useEffect(() => {
    const getClusters = async () => {
      const clustersData = await getClustersListService()

      const clusterMapped = clustersData?.map((item: ICluster) => {
        return {
          label: item.name,
          value: String(item.id),
        }
      })

      if (clusterMapped) {
        setData(clusterMapped)
        setOptions(clusterMapped)

        if (clusters.length > 0) {
          const clusterToEditMapped = clusterMapped.filter((item: any) =>
            clusters.includes(item.value)
          )
          setClusterSelected(clusterToEditMapped)
          setOptions((prevData) =>
            prevData.filter((item: any) => !clusters.includes(item.value))
          )
        }
      }
    }
    getClusters()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectCluster = (selectedItem: IOption) => {
    if (selectedItem.value === '0') {
      setOptions([{ label: '', value: '' }])
      setClusterSelected([selectedItem])
    } else {
      setClusterSelected((prevCluster) => [...prevCluster, selectedItem])

      setOptions((prevData) =>
        prevData.filter((item) => item.value !== selectedItem.value)
      )
    }
  }

  const handleDeleteCluster = (index: number, value: string) => {
    if (value === '0') {
      setClusterSelected([])
      setOptions(data)
    } else {
      const deletedItem = clusterSelected[index]

      setClusterSelected((prevCluster) => {
        const updatedCluster = [...prevCluster]
        updatedCluster.splice(index, 1)
        return updatedCluster
      })

      setOptions((prevData) => [
        ...prevData,
        { label: deletedItem.label, value: deletedItem.value },
      ])
    }
  }

  useEffect(() => {
    setClusters(clusterSelected)
  }, [clusterSelected, setClusters])

  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Cluster
      </Text>
      <Autocomplete
        options={options}
        value={[]}
        setValue={(e: [{ value: string; label: string }]) =>
          handleSelectCluster(e[0])
        }
        isMulti
      />
      <Box width={'24rem'} height={'16rem'} bg={'white'} overflowY="auto">
        <Table variant="simple">
          <Tbody>
            {clusterSelected.map((row, index) => (
              <Tr key={index}>
                <Td>{row.label}</Td>
                <Td
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Delete
                    onClick={() => handleDeleteCluster(index, row.value)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  )
}

export default Cluster
