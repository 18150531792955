import { useCallback, useState } from 'react'

import Cards from './Cards'
import Table from './Table'
import Header from './Header'
import IFilters from './types/IFilters'
import { Container } from './styles'

const PriceBaseTable: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [simpleView, setSimpleView] = useState(true)
  const [getBigData, setGetBigData] = useState(false)

  const toggleBigData = useCallback(() => {
    setGetBigData((current) => !current)
  }, [])

  return (
    <Container>
      <Header
        filters={filters}
        setFilters={setFilters}
        simpleView={simpleView}
        setSimpleView={setSimpleView}
      />
      <Cards getBigData={getBigData} />
      <Table
        filters={filters}
        simpleView={simpleView}
        toggleBigData={toggleBigData}
      />
    </Container>
  )
}

export default PriceBaseTable
