import { useCallback, useEffect, useState } from 'react'
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import { Box, Text } from '@chakra-ui/react'

import api from '../../../../../services/api'
import Paper from '../../../../../components/Paper'

import InputRelativity from './InputRelativity'
import { columns } from './constants'
import { Table, DeleteContainer } from './styles'

import { Title } from 'components/PageTitle/styles'
import AddComponent from './AddComponent'
import { DeleteComponent } from './DeleteComponent'
import { IMaterials } from '../types'

interface IProps {
  rows: IMaterials[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  captain: string
}

const Body: React.FC<IProps> = ({ rows, setRows, captain }) => {
  const [isAdding, setIsAdding] = useState(false)

  const [sku, setSku] = useState<IOption>({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<IOption[]>([])

  const [error, setError] = useState<string>('')

  useEffect(() => {
    setError('')
  }, [sku])

  const getSku = useCallback(() => {
    api.get('/relativity-sku/non-captains').then((res) => {
      setSkuOpt(
        res?.data?.data?.materials?.map(
          (item: IMaterials) =>
            +item?.sku && {
              label: `${+item?.sku} - ${item?.description}`,
              value: +item?.sku + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const onCancel = useCallback(() => {
    setSku({ label: '', value: '' })
    setError('')
    setIsAdding(false)
  }, [])
  useEffect(onCancel, [onCancel, rows])

  const onAdd = useCallback(() => {
    const url = `/relativity-sku/child/${captain}/${sku.value}`
    api
      .post(url)
      .then((res) => {
        onCancel()
        setRows((current) => [
          ...current,
          {
            sku: res.data?.data?.materials[0]?.sku,
            description: res.data?.data?.materials[0]?.description,
            relativity: res.data?.data?.materials[0]?.relativity,
          },
        ])
      })
      .catch((e) => {
        setError(e.response?.data?.message)
      })
  }, [sku, captain, onCancel, setRows])

  if (rows?.length === 0) return null

  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <Box
        display={!isAdding ? 'flex' : 'block'}
        justifyContent={'space-between'}
        marginBottom={'15px'}
      >
        <Title>
          <Text fontSize={'16pt'}>Produtos filho</Text>
        </Title>
        <AddComponent
          isAdding={isAdding}
          setIsAdding={() => setIsAdding(true)}
          sku={sku}
          skuOpt={skuOpt}
          setSku={setSku}
          error={error}
          onCancel={onCancel}
          onAdd={onAdd}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell key={column?.Header}>{column?.Header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row?.id}>
              <TableCell>{row?.sku}</TableCell>
              <TableCell>{row?.description}</TableCell>
              <TableCell>
                <InputRelativity row={row} captain={captain} />
              </TableCell>
              <TableCell>
                <DeleteContainer>
                  <DeleteComponent
                    row={row}
                    captain={captain}
                    setRows={setRows}
                  ></DeleteComponent>
                </DeleteContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Body
