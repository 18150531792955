import { Divider, Flex, HStack } from '@chakra-ui/react'

import Cards from './Cards'
import Graph from './Graph'
import Table from './Table'
import Header from './Header'

const Dashboard: React.FC = () => {
  return (
    <Flex flexDir="column">
      <Header />
      <Flex justify="center" color="#003b74" fontSize="2xl">
        Meta VS Realizado
      </Flex>
      <Divider mt="0.25rem" mb="1rem" />
      <Cards />
      <Flex mt="1rem" justify="center" color="#003b74" fontSize="2xl">
        Resumo de Solicitações de Desconto de Tabelas
      </Flex>
      <Divider mt="0.25rem" mb="1rem" />
      <HStack h="100%" align="flex-start">
        <Graph />
        <Table />
      </HStack>
    </Flex>
  )
}

export default Dashboard
