import styled from 'styled-components'
import { FaEye as Svg } from 'react-icons/fa'

export const ViewButton = styled(Svg)`
  cursor: pointer;

  color: #003b74;
  font-size: 1.5rem;
  transition: all 100ms ease-in-out;

  :hover {
    filter: brightness(1.25);
  }
`
