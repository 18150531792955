import { AxiosResponse } from 'axios'
import api from '../../services/api'

export const getItemsListService = async (id: number) => {
  const url = `/sceneries/${id}/items/A`

  //Deixei essa URL abaixo pra resolver se ainda vai ser reutilizada
  //   const oldUrl = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`
  
  return api
    .post(url)
    .then((response: AxiosResponse<any>) => {
      const items = response.data.data
      return items
    })
    .catch(() => {
      return []
    })
}
