import styled from 'styled-components'
import { AccordionPanel } from '@chakra-ui/react'

export const Container = styled(AccordionPanel)`
  margin: 1rem 0;
  padding: 2rem !important;

  border-radius: 1rem;
  background-color: #fbfbfb;

  input,
  button {
    height: 30px;
    max-width: 20rem;
  }
`
