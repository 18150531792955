import { useCallback } from 'react'
import { HStack, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IoPricetagsSharp as ParametersSvg } from 'react-icons/io5'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'

import IFilters from '../types/IFilters'
import FilterButton from './FilterButton'
import SendToApproveButton from './SendToApproveButton'
import { Container } from './styles'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  simpleView: boolean
  setSimpleView: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<IProps> = ({
  filters,
  setFilters,
  simpleView,
  setSimpleView,
}) => {
  const toast = useToast()
  const navigate = useNavigate()

  const toggleSimpleView = useCallback(() => {
    setSimpleView((current) => !current)
  }, [setSimpleView])

  const onSave = useCallback(() => {
    toast({
      title: 'Salvo com sucesso!',
      status: 'success',
    })
    navigate('/pricebase')
  }, [navigate, toast])

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle
          icon={<ParametersSvg />}
          title="Gestão de Preços"
          linkLabel="Cenário"
        />
      </HStack>
      <HStack spacing="1rem">
        <FilterButton filters={filters} setFilters={setFilters} />
        <Button onClick={toggleSimpleView}>
          Ver {simpleView ? 'Detalhado' : 'Simples'}
        </Button>
        <Button onClick={onSave}>Salvar Cenário</Button>
        <SendToApproveButton />
      </HStack>
    </Container>
  )
}

export default Header
