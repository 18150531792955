import { useCallback, useMemo } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'

interface IProps {
  end: string
  roundHouse: number
  setRoundhouse: React.Dispatch<React.SetStateAction<number>>
}

const Arrendondamento: React.FC<IProps> = ({
  end,
  roundHouse,
  setRoundhouse,
}) => {
  const value = useMemo(() => {
    setRoundhouse(0)
    return end.replace(/\d/g, 'X')
  }, [end, setRoundhouse])

  const getReverseIndex = useCallback(
    (index: number) => {
      return value.split('').length - index - 1
    },
    [value]
  )

  const checked = useCallback(
    (index: number) => {
      return getReverseIndex(index) <= roundHouse
    },
    [getReverseIndex, roundHouse]
  )

  const onCheck = useCallback(
    (index: number) => {
      const revIndex = getReverseIndex(index)
      setRoundhouse(revIndex)
    },
    [getReverseIndex, setRoundhouse]
  )

  if (!end) return null
  return (
    <VStack align="flex-start">
      <Text>Casa de arredondamento</Text>
      <HStack align="flex-start">
        {value.split('').map((char, index) => (
          <Text
            key={`${char}${index}`}
            fontSize="md"
            cursor={char === '.' ? 'default' : 'pointer'}
            color={checked(index) && char !== '.' ? 'red.500' : 'primary.500'}
            onClick={() => {
              if (char !== '.') onCheck(index)
            }}
          >
            {char === '.' ? char : checked(index) ? char : '0'}
          </Text>
        ))}
      </HStack>
    </VStack>
  )
}

export default Arrendondamento
