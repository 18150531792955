import { Text } from '@chakra-ui/react'
import {
  FaChevronRight as HiddenSvg,
  FaChevronDown as ExpandedSvg,
} from 'react-icons/fa'

interface IProps {
  showSubRow: boolean
  toggleSubRow: () => void
}

export const Expander: React.FC<IProps> = ({ showSubRow, toggleSubRow }) => {
  return (
    <Text onClick={toggleSubRow} cursor="pointer">
      {showSubRow ? <ExpandedSvg /> : <HiddenSvg />}
    </Text>
  )
}

export default Expander
