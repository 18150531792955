import { Text } from '@chakra-ui/react'
import {
  FaArrowUp as PositiveSvg,
  FaArrowDown as NegativeSvg,
} from 'react-icons/fa'

interface IProps {
  isPositive?: boolean
}

const Indicator: React.FC<IProps> = ({ isPositive = true }) => {
  return (
    <Text fontSize="1.25rem" color={`${isPositive ? 'green' : 'red'}.500`}>
      {isPositive ? <PositiveSvg /> : <NegativeSvg />}
    </Text>
  )
}

export default Indicator
