import { HStack, Text, VStack, Checkbox } from '@chakra-ui/react'

interface IProps {
  nivel: number
  setNivel: React.Dispatch<React.SetStateAction<number>>
}

const Level: React.FC<IProps> = ({ nivel, setNivel }) => {
  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Nível de Aprovador
      </Text>
      {[1, 2, 3, 4, 5].map((n) => (
        <HStack key={n}>
          <Checkbox
            size="lg"
            isChecked={nivel >= n}
            onChange={() => {
              setNivel(n)
            }}
          >
            <Text fontSize="1rem">Nível 0{n}</Text>
          </Checkbox>
        </HStack>
      ))}
    </VStack>
  )
}

export default Level
