import styled from 'styled-components'
import { FaEye as Svg } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'

export const ViewSvg = styled(Svg)`
  cursor: pointer;

  color: #003b74;
  font-size: 1.5rem;
  transition: all 100ms ease-in-out;

  :hover {
    filter: brightness(1.25);
  }
`

export const CheckIcon = styled(FaCheck)`
  cursor: pointer;
  color: #006400;
  font-size: 1.5rem;
  transition: all 100ms ease-in-out;

  :hover {
    filter: brightness(1.25);
  }
`
export const RefectIcon = styled(FaTimes)`
  cursor: pointer;
  fill: #8b4513;
  font-size: 1.5rem;
  transition: all 100ms ease-in-out;

  :hover {
    filter: brightness(1.25);
  }
`
