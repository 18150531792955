import { useCallback, useEffect, useState } from 'react'
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import Paper from 'components/Paper'

import Checkbox from './Checkbox'
import InputRelativity from './InputRelativity'
import { columns } from './constants'
import { Table } from './styles'

interface IProps {
  rows: any[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  clusterId: string
}

const Body: React.FC<IProps> = ({ rows, clusterId }) => {
  const [checked, setChecked] = useState('')

  const findCaptain = useCallback(() => {
    const captain = rows.find((r) => r.is_captain)
    return setChecked(captain ? captain.id : '')
  }, [rows])
  useEffect(findCaptain, [findCaptain])

  if (rows?.length === 0) return null
  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox
                  row={row}
                  clusterId={clusterId}
                  checked={checked}
                  setChecked={setChecked}
                  findCaptain={findCaptain}
                />
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <InputRelativity row={row} clusterId={clusterId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Body
