import React, { ButtonHTMLAttributes } from 'react'

import { Container } from './styles'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  type?: string
  width?: string
  padding?: string
  disable?: boolean
  onClick?: () => void
  containerStyle?: object
  children: Children
  bg?: string
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  width,
  children,
  bg,
  padding = '0.5rem 2rem',
  onClick,
  containerStyle = {},
  disable = false,
  ...rest
}) => (
  <Container
    width={width}
    style={containerStyle}
    bg={bg}
    type={type}
    disable={disable}
    padding={padding}
    onClick={() => {
      if (disable) return
      onClick?.()
    }}
    {...rest}
  >
    {children}
  </Container>
)

export default Button
