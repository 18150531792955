import { HStack } from '@chakra-ui/react'
import { IoPricetagsSharp as ParametersSvg } from 'react-icons/io5'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'

import { Container } from './styles'
import { useNavigate } from 'react-router-dom'

const Header: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <PageTitle icon={<ParametersSvg />} title="Gestão de Preços" />
      <HStack spacing="1rem">
        <Button onClick={() => navigate('/pricebase/create')}>
          Criação de Cenário
        </Button>
      </HStack>
    </Container>
  )
}

export default Header
