import { useCallback } from 'react'
import { Checkbox } from '@chakra-ui/react'

import IFilters from 'pages/ApprovalPriceBaseTableV2/types/IFilters'
import { useParams } from 'react-router-dom'
import api from 'services/api'

interface IProps {
  filters: IFilters
  getRows: () => void
  allChecked: boolean
  setAllChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderCheckbox: React.FC<IProps> = ({
  filters,
  getRows,
  allChecked,
  setAllChecked,
}) => {
  const { id } = useParams()

  const onChange = useCallback(() => {
    const url = `/sceneries/${id}/items/enable`
    const req: { [key: string]: any } = { enabled: !allChecked }

    Object.keys(filters).forEach((key) => {
      req[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api.post(url, req).then(() => {
      getRows()
      setAllChecked((current) => !current)
    })
  }, [id, allChecked, filters, getRows, setAllChecked])

  return (
    <Checkbox
      ml="1.5rem"
      size="lg"
      isChecked={allChecked}
      onChange={onChange}
    />
  )
}

export default HeaderCheckbox
