import React, { useCallback, useState } from 'react'
import { Input } from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'
import moment from 'moment'

const DateIni: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState<string>(row?.start_date)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = value
      setValue(e.target.value)

      if (row?.start_date && moment(row?.start_date).isBefore(e.target.value)) {
        return setValue(current)
      }

      const url = '/politics'
      const req = {
        cluster_id: Number(row?.cluster_id),
        captain_code: String(row?.sku),
        start_date: e.target.value,
      }
      api.put(url, req).catch(() => {
        setValue(row?.start_date)
      })
    },
    [row.start_date, row.cluster_id, value, row.sku]
  )

  return (
    <Input
      w="12rem"
      type="date"
      value={value}
      onChange={onChange}
      onKeyDown={(e) => e.preventDefault()}
    />
  )
}

export default DateIni
