// Used to format values in all scenery steps

const toFixed = (num: any, fixed: any) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return num.toString().match(re)?.[0]
}

const scenValue = (val: string | number, cases = 2) => {
  if (!+val) return ''

  // Don't complain
  let newVal = (+toFixed(+val, cases) + '').replace('.', ',')

  // Int fix
  if (!newVal.includes(',')) newVal += ',00'

  // Ex: Formats R$ 142,3 to R$ 142,30 (changes based on value of cases)
  if (newVal.includes(',')) {
    const newValSplit = newVal.split(',')

    if (newValSplit[1].length < cases) {
      const zeros = '0'.repeat(cases - newValSplit[1].length)
      newVal = newValSplit[0] + ',' + newValSplit[1] + zeros
    }
  }

  return newVal
}

export default scenValue
