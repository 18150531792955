import styled from 'styled-components'
import Select from 'react-select'

export const StyledAutocomplete = styled(Select)`
  width: 100%;

  input {
    border: 1px solid transparent !important;
  }

  .css-qc6sy-singleValue {
    padding-left: 0.5rem;
  }
`
