import React, { useCallback, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import api from '../../../../../../services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  param: string
  clusterId: string
  updateRow: (newRow: { [key: string]: any }) => void
  disabled?: boolean
}

const Input: React.FC<IProps> = ({
  row,
  param,
  clusterId,
  updateRow,
  disabled,
}) => {
  const [value, setValue] = useState<number | string>(
    Number(row[param]).toFixed(2) || 0.0
  )

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    if (value === row[param]) return

    const url = '/market-margins'
    const req: { [key: string]: any } = {
      level1_code: row?.level1_code,
      level2_code: row?.level2_code,
      level3_code: row?.level3_code,
      level4_code: row?.level4_code,
      level5_code: row?.level5_code,
      cluster_id: Number(clusterId),
      channel_code: row?.channel_code,
      [param]: +value,
    }

    api
      .put(url, req)
      .then((res) => {
        updateRow(res.data?.data?.market_margin)
      })
      .catch((error) => {
        setValue(row[param])
      })
  }, [clusterId, param, row, updateRow, value])

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <ChakraInput
        disabled={disabled === true}
        type="number"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </InputGroup>
  )
}

export default Input
