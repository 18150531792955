import Inputs from './Inputs'
import Header from './Header'
import { Container } from './styles'

const UsersEdit: React.FC = () => {
  return (
    <Container>
      <Header />
      <Inputs />
    </Container>
  )
}

export default UsersEdit
