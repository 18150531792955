import styled, { css } from 'styled-components'

interface DropdownBtnProps {
  width?: string
  open: boolean
}

interface DropdownContentProps {
  open: boolean
}

export const Container = styled.div`
  position: relative;
  display: inline-block;
`

export const DropdownBtn = styled.button<DropdownBtnProps>`
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;

  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  width: ${({ width }) => (width ? width : '15rem')};

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  border-radius: 20px;

  ${({ open }) =>
    open &&
    css`
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;

      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;

      border: 1px solid rgba(255, 255, 255);
    `}
`

export const DropdownContent = styled.div<DropdownContentProps>`
  display: none;
  position: absolute;
  background-color: #fff;
  z-index: 1;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ open }) =>
    open &&
    css`
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 1rem;

      align-items: center;
    `}
`

export const IconContainer = styled.div`
  display: flex;
  width: 3.3rem;
  justify-content: flex-end;

  svg {
    font-size: 18px;
  }
`
