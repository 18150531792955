import { useCallback } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import Paper from 'components/Paper'
import scenValue from 'utils/scenValue'

import IData from '../types/IData'
import SubSection from './SubSection'

interface IProps {
  info: IData
}

const Card: React.FC<IProps> = ({ info }) => {
  const invalid = useCallback((value: string | number) => {
    return +(value + '').replace(',', '.') ? false : '-'
  }, [])

  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    return newVal
  }, [])

  return (
    <Paper containerStyle={{ width: '100%', height: '17rem' }}>
      <Flex h="100%" flexDir="column" justify="space-between" color="#003b74">
        <Flex flexDir="column" align="center">
          <Text fontSize="md">{info.title}</Text>
          <Text color={`${info.color}.500`} fontSize="5xl" fontWeight="bold">
            {invalid(info.main) || `R$ ${scenValue(info.main)}`}
          </Text>
        </Flex>
        <Flex flexDir="column" align="center">
          <SubSection label="Volume">
            {invalid(info.volume) ||
              localeNumber(scenValue(Math.round(+info.volume), 0))}
          </SubSection>
          <SubSection label="Faturamento Bruto">
            {invalid(info.faturamento) ||
              `R$ ${localeNumber(scenValue(info.faturamento))}`}
          </SubSection>
          <SubSection label="Margem Bruta">
            {invalid(info.margem3) ||
              `R$ ${localeNumber(scenValue(info.margem3))}`}{' '}
            / {invalid(info.margem4) || `${scenValue(info.margem4, 2)}%`}
          </SubSection>
          <SubSection label="Margem Contribuição">
            {invalid(info.margem1) ||
              `R$ ${localeNumber(scenValue(info.margem1))}`}{' '}
            / {invalid(info.margem2) || `${scenValue(info.margem2, 2)}%`}
          </SubSection>
          {info.indice ? (
            <SubSection label="Índice">
              {invalid(info.indice) || `${scenValue(info.indice, 2)}%`}
            </SubSection>
          ) : (
            <Flex color="transparent">-</Flex>
          )}
        </Flex>
      </Flex>
    </Paper>
  )
}

export default Card
