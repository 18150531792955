import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { HStack, useToast } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  name: string
  type: string
  dateIni: string
  dateEnd: string
  filters: { [key: string]: IOption[] }
}

const Footer: React.FC<IProps> = ({
  name,
  type,
  filters,
  dateIni,
  dateEnd,
}) => {
  const toast = useToast()
  const navigate = useNavigate()

  const loadingRef = useRef<any>(null)

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const createScenery = useCallback(() => {
    const url =
      type === 'TABELA'
        ? '/sceneries/new-table-scenery'
        : '/sceneries/new-discount-scenery'
    const req: { [key: string]: string | string[] | number } = {
      name,
      type: type === 'TABELA' ? 'A' : 'B',
    }

    if (type === 'DESCONTO') {
      Object.keys(filters).forEach((key) => {
        if (filters[key].length > 0) {
          req[key] = filters[key].map((item) => item.value)
        }
      })
    }

    console.log(req, 'req')
    openLoading(
      'Criando cenário, por favor aguarde...',
      api
        .post(url, req)
        .then((res) => {
          const navUrl =
            type === 'TABELA'
              ? `/pricebase/table/${res.data.data.scenery[0].id}/A`
              : `/pricebase/discount/${res.data.data.scenery[0].id}/A`

          navigate(navUrl)
        })
        .catch(() => {
          toast({
            status: 'error',
            title: 'Erro ao criar, tente novamente.',
          })
        })
    )
  }, [type, name, openLoading, filters, navigate, toast])

  return (
    <HStack w="100%" justify="space-between">
      <Button onClick={() => navigate('/pricebase')}>Cancelar</Button>
      <Button disable={!name || !dateIni || !dateEnd} onClick={createScenery}>
        Criar Cenário
      </Button>
      <LoadingModal ref={loadingRef} />
    </HStack>
  )
}

export default Footer
