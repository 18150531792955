import { withStyles } from '@mui/styles'

const materialUITheme = withStyles(() => ({
  '@global': {
    html: {
      fontSize: 12,
    },
    '*:focus': {
      boxShadow: 'none !important',
    },
    input: {
      border: '1px solid #E2E8F0 !important',
      backgroundColor: '#FFF !important',
    },
    select: {
      border: '1px solid #E2E8F0 !important',
      backgroundColor: '#FFF !important',
    },
    '*::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      border: '10px solid transparent',
      borderRadius: '13px',
      backgroundClip: 'padding-box',
    },
    '*::-webkit-scrollbar-thumb': {
      background: '#7F7F7F !important',
      borderRadius: '13px',
      backgroundClip: 'padding-box',
    },
    '.chakra-checkbox__control': {
      boxShadow: 'none !important',
    },
    '.data-focus': {
      boxShadow: 'none !important',
    },
    '.data-checked': {
      boxShadow: 'none !important',
    },
  },
}))

export default materialUITheme
