import React, { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import { money } from '../../../../../../utils/masks'

import { IProps } from '../../types/IRow'

const Indice: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState<string>(row?.index)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(money(e.target.value))
  }, [])

  const onBlur = useCallback(() => {
    const url = '/politics'
    const req = {
      cluster_id: row?.cluster_id,
      captain_code: row?.sku,

      index: Number(value),
    }

    api.put(url, req).catch(() => {
      setValue(row?.index)
    })
  }, [row, value])

  return (
    <InputGroup w="8rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        minW="6rem"
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={row?.is_pricepoint}
      />
    </InputGroup>
  )
}

export default Indice
