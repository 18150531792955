import React, { useEffect, useState } from 'react'
import { VStack, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Nav from './Nav'
import Access from './Access'
import MainInputs from './MainInputs'
import { Roles } from 'pages/UsersEdit/Inputs/useEdit/types'
import { Values } from './Access/Approvals/UseSlider/types'
import { initialRoles, sliderInitialValues } from '../Types/initalValues'
import { createUserService } from '../Service/create-user-service'

import ModalConfirmNewUser from '../Components/ModalConfirmNewUser'

const Inputs: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [name, setName] = useState<string>('')
  const [roles, setRoles] = useState<Roles[]>(initialRoles)
  const [nivel, setNivel] = useState<number>(1)
  const [email, setEmail] = useState<string>('')
  const [access, setAccess] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  const [enrollment, setEnrollment] = useState<string>('')
  const [approvalScopes, setApprovalScopes] =
    useState<Values[]>(sliderInitialValues)
  const [clusters, setClusters] = useState<string[]>([])
  const [openNewUser, setOpenNewUser] = useState<boolean>(false)

  useEffect(() => {
    if (nivel > approvalScopes.length) {
      const newScopes = Array.from(
        { length: nivel - approvalScopes.length },
        () => ({ initial: 0, final: 20, level: nivel })
      )
      setApprovalScopes((prevScopes) => [...prevScopes, ...newScopes])
    }

    if (nivel < approvalScopes.length) {
      setApprovalScopes((prevScopes) => prevScopes.slice(0, nivel))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nivel])

  const onSave = async (): Promise<void> => {
    await createUserService({
      name,
      roles,
      nivel,
      email,
      access,
      active,
      enrollment,
      approvalScopes,
      clusters,
    }).then((result: { status: number; message: string }) => {
      if (result.status === 200) {
        setOpenNewUser(true)
        // navigate('/users')
        toast({
          title: result.message,
          status: 'success',
        })
      } else {
        toast({
          title: result.message,
          status: 'error',
        })
      }
    })
  }

  const onCancel = () => {
    navigate('/users')
    setOpenNewUser(false)
  }

  const handleNewUser = () => {
    setApprovalScopes(sliderInitialValues)
    setClusters([])
    setName('')
    setEmail('')
    setNivel(1)
    setRoles(initialRoles)
    setActive(false)
    setEnrollment('')
    setOpenNewUser(false)
    navigate('/users/register')
  }

  const handleChangeSliderValues = (values: Values, index: number) => {
    const updatedValues = [...approvalScopes]
    updatedValues[index] = values
    setApprovalScopes(updatedValues)
  }

  return (
    <VStack w="100%" align="flex-start" spacing="1rem">
      <Nav
        name={name}
        email={email}
        enrollment={enrollment}
        onSave={onSave}
        onCancel={onCancel}
      />
      <MainInputs
        name={name}
        email={email}
        access={access}
        active={active}
        enrollment={enrollment}
        setName={setName}
        setEmail={setEmail}
        setAccess={setAccess}
        setActive={setActive}
        setEnrollment={setEnrollment}
      />
      <Access
        nivel={nivel}
        roles={roles}
        active={active}
        setNivel={setNivel}
        setRoles={setRoles}
        handleChangeSliderValues={handleChangeSliderValues}
        sliderValues={approvalScopes}
        setClusters={setClusters}
      />
      <ModalConfirmNewUser
        isOpen={openNewUser}
        onClose={() => setOpenNewUser(false)}
        onConfirm={handleNewUser}
        onCancel={onCancel}
      />
    </VStack>
  )
}

export default Inputs
