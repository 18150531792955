import React, { useCallback, useMemo, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import { IProps } from '../../types/IRow'

const PriceType: React.FC<IProps> = ({ row, priceTypeOpt }) => {
  const [value, setValue] = useState<string>(row.source)

  const options = useMemo(() => {
    return row.is_pricepoint
      ? priceTypeOpt?.pricepoint
      : priceTypeOpt?.not_pricepoint
  }, [
    priceTypeOpt?.not_pricepoint,
    priceTypeOpt?.pricepoint,
    row?.is_pricepoint,
  ])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics'
      const req = {
        cluster_id: row?.cluster_id,
        source: newVal,
        captain_code: row?.sku,
      }

      api.put(url, req).catch(() => {
        setValue(row?.price_type)
      })
    },
    [row]
  )

  return (
    <Select
      w="11rem"
      value={value}
      onChange={onChange}
      disabled={row?.is_pricepoint}
    >
      {options?.map?.((option) => (
        <option key={option?.label} value={option?.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default PriceType
