import { useCallback, useEffect, useMemo, useState } from 'react'
import { HStack, VStack } from '@chakra-ui/react'

import LoadingScreen from 'components/LoadingScreen'

import Header from './Header'
import Filters from './Filters'
import calcValue from './actions/calcValue'
import HistoricData from './HistoricData'
import PriceToPractice from './PriceToPractice'
import SeasonalityWeek from './SeasonalityWeek'
import SeasonalityMonth from './SeasonalityMonth'
import ProductMarketShare from './ProductMarketShare'
import ProductsNegativeImpact from './ProductsNegativeImpact'
import ProductsPositiveImpact from './ProductsPositiveImpact'
import ManufacturerMarketShare from './ManufacturerMarketShare'
import PredictedVSAccomplished from './PredictedVSAccomplished'
import { Container } from './styles'
import { MOCK_PRODUCT_INFO } from './MOCK_DATA'

interface IProductInfo {
  dados_historico: { [key: string]: any }
  preco_praticar: string
  sazonalidade_do_produto_semana: { [key: string]: any }
  sazonalidade_do_produto_mensal: { [key: string]: any }
  produtos_com_impacto_positivo: { [key: string]: any }[]
  produtos_com_impacto_negativo: { [key: string]: any }[]
}

const PricesOptimizer: React.FC = () => {
  const [price, setPrice] = useState('')
  const [ready, setReady] = useState(false)
  const [productId, setProductId] = useState('')
  const [productInfo, setProductInfo] = useState({} as IProductInfo)

  const calculation = useMemo(() => {
    if (!price || !productId) {
      return {
        'quantidade_vendida_m+1': 0,
        'preco_medio_ponderado_m+1': 0,
        'market_share_do_produto_m+1': '',
      }
    }

    return calcValue(+price.replace(',', '.'), +productId)
  }, [price, productId])

  useEffect(() => {
    setReady(false)
  }, [price, productId])

  const getPrice = useCallback(() => {
    if (!productId) return

    const newPrice = MOCK_PRODUCT_INFO.data[productId].preco_praticar.replace(
      '.',
      ','
    )

    setPrice(newPrice)
  }, [productId])
  useEffect(getPrice, [getPrice])

  const getProductInfo = useCallback(() => {
    if (!productId) return setProductInfo({} as IProductInfo)

    const newProductInfo = MOCK_PRODUCT_INFO.data[productId]

    newProductInfo.dados_historico['quantidade_vendida_m+1'] = Math.floor(
      calculation['quantidade_vendida_m+1']
    )

    newProductInfo.dados_historico['market_share_do_produto_m+1'] =
      calculation['market_share_do_produto_m+1'] + '%'

    newProductInfo.dados_historico['preco_medio_ponderado_m+1'] =
      'R$ ' +
      (calculation['preco_medio_ponderado_m+1'].toFixed(2) + '').replace(
        '.',
        ','
      )

    setProductInfo(newProductInfo)
    setTimeout(() => setReady(true), 1000)
  }, [calculation, productId])
  useEffect(getProductInfo, [getProductInfo])

  return (
    <Container>
      <Header />
      {!ready && productId && <LoadingScreen />}
      <HStack w="100%" mb="1rem" spacing="1rem" align="flex-start">
        <VStack
          w="100%"
          maxW="45vw"
          align="flex-start"
          spacing="1rem"
          pos="sticky"
          top="0"
        >
          <Filters setProductId={setProductId} />
          {productId && (
            <PriceToPractice
              price={price}
              setPrice={setPrice}
              getPrice={getPrice}
            />
          )}
          {ready && (
            <>
              <ProductMarketShare
                productId={productId}
                calculation={calculation}
              />
              <ManufacturerMarketShare productId={productId} />
            </>
          )}
        </VStack>
        <VStack w="100%" align="flex-start" spacing="1rem" pos="sticky" top="0">
          {ready && (
            <>
              <HistoricData data={productInfo?.dados_historico} />
              <SeasonalityWeek
                data={productInfo?.sazonalidade_do_produto_semana}
              />
              <SeasonalityMonth
                data={productInfo?.sazonalidade_do_produto_mensal}
              />
              <ProductsPositiveImpact
                data={productInfo?.produtos_com_impacto_positivo}
              />
              <ProductsNegativeImpact
                data={productInfo?.produtos_com_impacto_negativo}
              />
            </>
          )}
        </VStack>
      </HStack>
      {ready && <PredictedVSAccomplished productId={productId} />}
    </Container>
  )
}

export default PricesOptimizer
