import React, { useMemo } from 'react'
import { Text, VStack, CheckboxGroup } from '@chakra-ui/react'

const Modules: React.FC = () => {
  const mods = useMemo(
    () => [
      { name: 'Parâmetros' },
      { name: 'Simulador Preço Tabela' },
      { name: 'Simulador Preço Desconto' },
    ],
    []
  )

  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Módulos / Funções
      </Text>
      <CheckboxGroup size="lg">
        <VStack align="flex-start">
          {mods.map((mod) => (
            <Text key={mod.name} fontSize="1rem">
              {mod.name}
            </Text>
          ))}
        </VStack>
      </CheckboxGroup>
    </VStack>
  )
}

export default Modules
