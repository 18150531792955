import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import { Flex, Text, VStack } from '@chakra-ui/react'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js'

import { defaultData, options } from './constants'
import { MOCK_MARKET_SHARE_GRAPH } from '../MOCK_DATA'

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface IProps {
  productId: string
  calculation: {
    'quantidade_vendida_m+1': number
    'preco_medio_ponderado_m+1': number
    'market_share_do_produto_m+1': string
  }
}

const ProductMarketShare: React.FC<IProps> = ({ productId, calculation }) => {
  const [ready, setReady] = useState(false)
  const [graphData, setGraphData] = useState(defaultData)

  useEffect(() => {
    setReady(false)
  }, [productId])

  const getData = useCallback(() => {
    if (!productId) return

    const product = MOCK_MARKET_SHARE_GRAPH[productId]

    product.at(-1).value = calculation['market_share_do_produto_m+1']

    const newData = { ...defaultData }

    newData.labels = product.map((month: any) => month.month)
    newData.datasets[0].data = product.map((month: any) => month.value)
    newData.datasets[0].backgroundColor = _.times(product.length).map(
      (_, index) => (index < product.length - 1 ? '#63B3ED' : '#F6AD55')
    )

    setGraphData(newData)
    setTimeout(() => setReady(true), 0)
  }, [calculation, productId])
  useEffect(getData, [getData])

  if (!productId || !ready) return null
  return (
    <VStack
      w="100%"
      h="20rem"
      p="1rem"
      align="flex-start"
      bgColor="#fff"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.200"
    >
      <Text fontWeight="semibold" fontSize="md">
        Market Share do Produto
      </Text>
      <Flex w="100%" h="100%">
        <Bar options={options} data={graphData} />
      </Flex>
    </VStack>
  )
}

export default ProductMarketShare
