import { FaHome as DashboardSvg } from 'react-icons/fa'
import { Flex } from '@chakra-ui/react'

import PageTitle from 'components/PageTitle'

const Header: React.FC = () => {
  return (
    <Flex>
      <PageTitle icon={<DashboardSvg />} title="Início" />
    </Flex>
  )
}

export default Header
