import { HStack, Input, Select, Text, VStack } from '@chakra-ui/react'

import { money } from 'utils/masks'

import Example from './Example'
import Accepted from './Accepted'
import Roundhouse from './Roundhouse'

interface IProps {
  end: string
  setEnd: React.Dispatch<React.SetStateAction<string>>
  start: string
  setStart: React.Dispatch<React.SetStateAction<string>>
  round: string
  setRound: React.Dispatch<React.SetStateAction<string>>
  setExample: React.Dispatch<React.SetStateAction<string>>
  accepted: number[]
  setAccepted: React.Dispatch<React.SetStateAction<number[]>>
  roundHouse: number
  setRoundhouse: React.Dispatch<React.SetStateAction<number>>
}

const ArredondamentoCreate: React.FC<IProps> = ({
  end,
  setEnd,
  start,
  setStart,
  round,
  setRound,
  setExample,
  accepted,
  setAccepted,
  roundHouse,
  setRoundhouse,
}) => {
  return (
    <VStack align="flex-start" spacing={8}>
      <HStack spacing={8}>
        <VStack align="flex-start">
          <Text>Faixa inicial</Text>
          <Input
            value={start}
            onChange={(e) => setStart(money(e.target.value))}
          />
        </VStack>
        <VStack align="flex-start">
          <Text>Faixa final</Text>
          <Input
            value={end}
            onChange={(e) => {
              setEnd(money(e.target.value))
              setRoundhouse(0)
            }}
          />
        </VStack>
        <VStack align="flex-start">
          <Text>Arredondar</Text>
          <Select value={round} onChange={(e) => setRound(e.target.value)}>
            <option value="CEILING">Para cima</option>
            <option value="FLOOR">Para baixo</option>
          </Select>
        </VStack>
      </HStack>
      <HStack spacing={6}>
        <Accepted accepted={accepted} setAccepted={setAccepted} />
        <Roundhouse
          end={end}
          roundHouse={roundHouse}
          setRoundhouse={setRoundhouse}
        />
      </HStack>
      <Example
        end={end}
        accepted={accepted}
        roundHouse={roundHouse}
        setExample={setExample}
      />
    </VStack>
  )
}

export default ArredondamentoCreate
