import StylesProvider from 'providers/StylesProvider'
import RoutesProvider from 'providers/RoutesProvider'
import UserContextProvider from '../../state/user-context'

const AppProvider: React.FC = () => {
  return (
    <StylesProvider>
      <UserContextProvider>
        <RoutesProvider />
      </UserContextProvider>
    </StylesProvider>
  )
}

export default AppProvider
