export const sliderInitialValues = [
  { initial: 0, final: 20, level: 1 },
]

export const initialRoles = [
  {
      "id": 2,
      "name": "READ_PARAMETERS"
  },
  {
      "id": 4,
      "name": "READ_SCENERY"
  },
  {
      "id": 6,
      "name": "READ_DISCOUNT_SCENERY"
  }
]
