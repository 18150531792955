import { useCallback } from 'react'
import { Container, Label, InputStyled } from './styles'

interface IProps {
  label: string
  placeholder: string
  type?: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  onKeyPress?: (e: any) => void
}

const Input: React.FC<IProps> = ({
  label,
  placeholder,
  type = 'text',
  value,
  setValue,
  onKeyPress,
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  return (
    <Container>
      <Label>{label}</Label>
      <InputStyled
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={(e: any) => {
          if (onKeyPress) onKeyPress(e)
        }}
      />
    </Container>
  )
}

export default Input
