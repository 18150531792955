import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { HStack } from '@chakra-ui/react'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button'
import { Container } from './styles'
import { FaUsers as UsersSvg } from 'react-icons/fa'
import { UserContext } from '../../../state/user-context'

const Header: React.FC = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const newUser = useCallback(() => {
    navigate('/users/register')
  }, [navigate])

  return (
    <Container>
      <PageTitle icon={<UsersSvg />} title="Controle de Acessos" />
      <HStack spacing={4}>
        <Button disable={!user?.access} onClick={newUser}>
          Novo
        </Button>
      </HStack>
    </Container>
  )
}

export default Header
