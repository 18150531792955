import React, { useMemo } from 'react'

import TableBackend from 'components/TableBackend'

import IRow, { ITableProps } from '../types/IRow'
import Ean from './Ean'
import Indice from './Indice'
import Channel from './Channel'
import DateEnd from './DateEnd'
import DateIni from './DateIni'
import PriceType from './PriceType'
import Pricepoint from './Pricepoint'
import CalculationType from './CalculationType'

interface ICellProps {
  row: IRow
  updateCell: (accessor: string, newValue: any) => void
}

const Table: React.FC<ITableProps> = ({
  rows,
  channels,
  eanOpt,
  priceTypeOpt,
  page,
  limit,
  total,
  setPage,
  totalPages,
}) => {
  const cols = useMemo(() => {
    return [
      {
        Header: 'Cluster de Preço',
        accessor: 'cluster_name',
      },
      {
        Header: 'SKU Capitão',
        accessor: 'sku',
      },
      {
        Header: 'Descrição',
        accessor: 'level5_name',
      },
      {
        Header: 'Canal',
        accessor: ({ row }: ICellProps) => (
          <Channel row={row} channels={channels} />
        ),
      },
      {
        Header: 'PricePoint',
        accessor: ({ row, updateCell }: ICellProps) => (
          <Pricepoint row={row} updateCell={updateCell} />
        ),
      },
      {
        Header: 'Tipo de Preço',
        accessor: ({ row }: ICellProps) => (
          <PriceType row={row} priceTypeOpt={priceTypeOpt} />
        ),
      },
      {
        Header: 'Descrição Concorrente',
        accessor: ({ row }: ICellProps) => <Ean row={row} eanOpt={eanOpt} />,
      },
      {
        Header: 'Tipo de Cálculo',
        accessor: ({ row }: ICellProps) => <CalculationType row={row} />,
      },
      {
        Header: 'Índice',
        accessor: ({ row }: ICellProps) => <Indice row={row} />,
      },
      {
        Header: 'Data Início Período',
        accessor: ({ row }: ICellProps) => <DateIni row={row} />,
      },
      {
        Header: 'Data Fim Período',
        accessor: ({ row }: ICellProps) => <DateEnd row={row} />,
      },
    ]
  }, [channels, eanOpt, priceTypeOpt])

  return (
    <TableBackend
      rows={rows}
      cols={cols}
      page={page}
      limit={limit}
      total={total}
      setPage={setPage}
      totalPages={totalPages}
    />
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
