import React, { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'

const Ean: React.FC<IProps> = ({ row, eanOpt }) => {
  const [value, setValue] = useState<string>(row?.competitor_ean)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)
      const url = '/politics'
      const req = {
        cluster_id: row?.cluster_id,
        captain_code: row?.sku,
        competitor_ean: newVal,
      }

      api.put(url, req).catch(() => {
        setValue(row?.competitor_ean)
      })
    },
    [row]
  )

  return (
    <Select
      w="20rem"
      value={value}
      onChange={onChange}
      disabled={row?.is_pricepoint}
    >
      {eanOpt?.map?.((option) => (
        <option key={option?.label} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </Select>
  )
}

export default Ean
