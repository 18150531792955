import { BiTrendingUp as OptimizerSvg } from 'react-icons/bi'

import PageTitle from 'components/PageTitle'

import ButtonRequestApproval from './ButtonRequestApproval'
import { Container } from './styles'

const Header: React.FC = () => {
  return (
    <Container>
      <PageTitle
        icon={<OptimizerSvg size={30} />}
        title="Otimizador De Preços"
      />
      <ButtonRequestApproval />
    </Container>
  )
}

export default Header
