import { useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'

import { Delete } from './styles'

interface IRow {
  id: number | string
  name: string
  factory_name: string
}

interface IProps {
  row: IRow
}

const DeleteButton: React.FC<IProps> = ({ row }) => {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const onDelete = useCallback(() => {
    api
      .delete(`/clusters/${row.id}`)
      .then(() => {
        onClose()
        toast({
          title: 'Deletado com sucesso!',
          status: 'warning',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro ao excluír, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [onClose, row.id, toast])

  return (
    <>
      <Delete onClick={onOpen} />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack alignItems="flex-start" fontSize="md" spacing={0.5}>
                <Text color="#D20404">Essa ação não poderá ser desfeita!</Text>
                <Text>Fábrica: {row.factory_name}</Text>
                <Text>Nome do Cluster: {row.name}</Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  containerStyle={{ backgroundColor: '#D20404' }}
                  onClick={onDelete}
                >
                  Deletar
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteButton
