import INiveisIniState from './types/INiveisIniState'

const niveisIniState: INiveisIniState = {
  '1': [],
  '2': [],
  '3': [],
  '4': [],
  '5': [],
  '1opt': [],
  '2opt': [],
  '3opt': [],
  '4opt': [],
  '5opt': [],
}

export default niveisIniState
