import React, { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'

const Channel: React.FC<IProps> = ({ row, channels }) => {
  const [value, setValue] = useState<string>(row.channel_code)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics'
      const req = {
        captain_code: row?.sku,
        cluster_id: row?.cluster_id,

        channel_code: newVal,
      }

      api.put(url, req).catch(() => {
        setValue(row?.channel_code)
      })
    },
    [row]
  )

  return (
    <Select w="11rem" value={value} onChange={onChange}>
      {channels?.map((option) => (
        <option key={option?.label} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </Select>
  )
}

export default Channel
