import { useState, ChangeEvent } from 'react'
import {
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  Button,
} from '@chakra-ui/react'
import Table from './Table'
import Header from './Header'
import { Container } from './styles'
import Modal from 'components/Modal'
import { handleApproveRefectService } from './Service/handle-approve-reject.service'

const Approval: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [ids, setIds] = useState<number[]>([])
  const [type, setType] = useState<string>('')

  const handleSubmit = async () => {
    await handleApproveRefectService({ ids, type, reason: text }).then(
      (res: any) => {
        //Tratar esse RES para mostrar um toast indicando que deu certo
        console.log(res, 'res')
        window.location.reload()
      }
    )
  }

  const ModalBody = () => {
    return (
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Escreva o motivo</FormLabel>
          <Textarea
            placeholder="Digite aqui..."
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setText(e.target.value)
            }
          />
        </FormControl>
      </VStack>
    )
  }

  const handleClickRow = (id: number) => {
    setIds((prevIds) => {
      const isIdPresent = prevIds.includes(id)

      if (isIdPresent) {
        return prevIds.filter((existingId) => existingId !== id)
      } else {
        return [...prevIds, id]
      }
    })
  }

  const handleApproveReject = (type: string) => {
    setOpenModal(true)
    setType(type)
  }

  return (
    <Container>
      {openModal && (
        <Modal
          isOpen={true}
          onClose={() => setOpenModal(false)}
          w={'560px'}
          title={`Confirme que está ${
            type === 'approve' ? 'APROVANDO' : 'RECUSANDO'
          } o(s) cenário(s)?`}
          body={<ModalBody />}
          footer={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                colorScheme="gray"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Cancelar
              </Button>
              <div style={{ width: 20 }}></div>
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmit()
                }}
              >
                Confirmar
              </Button>
            </div>
          }
        />
      )}

      <Header handleApproveReject={handleApproveReject} disabled={ids.length < 1} />
      <Table handleClickRow={handleClickRow} ids={ids} />
    </Container>
  )
}

export default Approval
