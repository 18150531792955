import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import api from 'services/api'
import TableBackend from 'components/TableBackend'

import IRow from '../../../types/IRow'
import { ViewButton } from './styles'

interface IProps {
  row: IRow
}

interface IExcelRow {
  id: number
  upload_id: number
  date: string
  captain_sku: number
  description: string
  uf: string
  city: string
  channel: string
  discounted_price: number
  regular_price: number
  created_at: string
  updated_at: string
}

const limit = 15

const View: React.FC<IProps> = ({ row }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [rows, setRows] = useState<IExcelRow[]>([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const resetData = useCallback(() => {
    if (!isOpen) {
      setRows([])
      setLoading(true)
      setPage(1)
      setTotal(0)
      setTotalPage(1)
    }
  }, [isOpen])
  useEffect(resetData, [resetData])

  const getRows = useCallback(() => {
    isOpen &&
      api
        .get(`/market-prices?&upload_id=${row.id}`)
        .then((response) => {
          setRows(response.data.data.data.payload)
          setTotal(response.data.data.data.total)
          setTotalPage(response.data.data.data.totalPages)
          setLoading(false)
        })
        .catch(() => {
          if (!toast.isActive('viewdata')) {
            toast({
              id: 'viewdata',
              title: 'Erro ao carregar dados, tente novamente.',
              status: 'error',
              isClosable: true,
            })
          }
        })
  }, [isOpen, row.id, toast])
  useEffect(getRows, [getRows])

  const cols = useMemo(() => {
    if (row.type === 'PRICEPOINT') {
      return [
        {
          Header: 'Data Preço',
          accessor: ({ row }: any) =>
            row.price_date && (
              <>{moment(row.price_date).zone(0).format('DD/MM/YYYY')}</>
            ),
        },
        { Header: 'Cap SKU', accessor: 'captain_code' },
        { Header: 'Descrição', accessor: 'description' },
        { Header: 'UF', accessor: 'uf' },
        { Header: 'Canal do PDV', accessor: 'channel_name' },
        { Header: 'Preço Ponderado', accessor: 'sales_price' },
        { Header: 'Preço Regular', accessor: 'regular_price' },
      ]
    }

    return [
      {
        Header: 'Data Preço',
        accessor: ({ row }: any) =>
          row.price_date && (
            <>{moment(row.price_date).zone(0).format('DD/MM/YYYY')}</>
          ),
      },
      { Header: 'EAN', accessor: 'ean' },
      { Header: 'Descrição Concorrente', accessor: 'description' },
      { Header: 'UF', accessor: 'uf' },
      { Header: 'Canal do PDV', accessor: 'channel_name' },
      { Header: 'Preço Ponderado', accessor: 'sales_price' },
      { Header: 'Preço Regular', accessor: 'regular_price' },
      { Header: 'Fonte Pesquisa', accessor: 'research_source' },
    ]
  }, [row.type])

  return (
    <>
      <ViewButton onClick={onOpen} />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="80vw">
          <ModalHeader>
            Visualizar: {row.id} - {row.name} - {row.type} -{' '}
            {moment(row.date).zone(0).format('DD/MM/YYYY')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex overflowX="auto" justify="center">
              {loading ? (
                <Spinner mb={12} size="xl" />
              ) : (
                <TableBackend
                  rows={rows}
                  cols={cols}
                  page={page}
                  limit={limit}
                  total={total}
                  setPage={(newPage) => {
                    setLoading(true)
                    setPage(newPage)
                  }}
                  totalPages={totalPages}
                />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default View
