import Money from '../Money'
import Percent from '../Percent'

export const cols = [
  { Header: 'Cliente', accessor: 'client_name' },
  { Header: 'Cluster', accessor: 'name' },
  { Header: 'Fábrica', accessor: 'factory' },
  { Header: 'UF Destino', accessor: 'uf_destiny' },
  { Header: 'Canais', accessor: 'nome_canal' },
  { Header: 'Cód. Capitão', accessor: 'cod_capitan' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Classificação do Item', accessor: 'classificacao_produto' },
  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'PPV Atual',
    accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_sugestao} />,
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <Percent value={row.adicional_contrato} />,
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.margem_objetiva} />,
  },
  {
    Header: 'Mrg PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.margem_ppv_novo} />,
  },
  {
    Header: 'PPV Bruto Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_bruto_nf} />,
  },
  {
    Header: 'PPV Líquido Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_liquido} />,
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice} />,
  },
  { Header: 'Nível 1', accessor: 'nivel01' },
  { Header: 'Nível 2', accessor: 'nivel02' },
  { Header: 'Nível 3', accessor: 'nivel03' },
  { Header: 'Nível 4', accessor: 'nivel04' },
  { Header: 'Nível 5', accessor: 'nivel05' },
  {
    Header: 'PPC Base',
    accessor: ({ row }: any) => <Money value={row.ppc_inicial} />,
  },
  {
    Header: 'Índice Meta',
    accessor: ({ row }: any) => <Percent value={row.indice_meta} />,
  },
  {
    Header: 'PPC Index',
    accessor: ({ row }: any) => <Money value={row.ppc_index} />,
  },
  {
    Header: 'Relatividade SKU',
    accessor: ({ row }: any) => <Percent value={row.relatividade_sku} />,
  },
  {
    Header: 'PPC Relativ.',
    accessor: ({ row }: any) => <Money value={row.ppc_relativo} />,
  },
  {
    Header: 'PIS+COFINS Destino',
    accessor: ({ row }: any) => <Percent value={row.pis_cofins_destino} />,
  },
  {
    Header: 'ICMS Destino',
    accessor: ({ row }: any) => <Percent value={row.icms_destino} />,
  },
  {
    Header: 'PPC S/ Mrg',
    accessor: ({ row }: any) => <Money value={row.ppc_s_mg_varejo} />,
  },
  { Header: 'Tipo Tributação', accessor: 'tipo_imposto' },
  {
    Header: 'IPI',
    accessor: ({ row }: any) => <Percent value={row.ipi_percent} />,
  },
  {
    Header: 'MVA',
    accessor: ({ row }: any) => <Percent value={row.mva_percent} />,
  },
  {
    Header: 'ICMS ST',
    accessor: ({ row }: any) => <Percent value={row.icms_st_percent} />,
  },
  {
    Header: 'Relat. Canal',
    accessor: ({ row }: any) => <Percent value={row.relatividade_canal} />,
  },
  {
    Header: 'PPV Relat.',
    accessor: ({ row }: any) => <Money value={row.preco_relativo_canal} />,
  },
  {
    Header: 'Mrg Mín',
    accessor: ({ row }: any) => <Money value={row.margem_min_valor} />,
  },
  {
    Header: 'Mrg Máx',
    accessor: ({ row }: any) => <Money value={row.margem_max_valor} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_sugestao} />,
  },
  {
    Header: 'Novo PPC S/ Mrg',
    accessor: ({ row }: any) => <Money value={row.novo_ppc_sem_margem} />,
  },
  {
    Header: 'Mrg Mercado',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
  },
  { Header: 'Novo PPC', accessor: ({ row }: any) => <Money value={row.ppc} /> },
  {
    Header: 'Desconto',
    accessor: ({ row }: any) => <Percent value={row.percentual_desconto} />,
  },
  {
    Header: 'Novo PPC Desconto',
    accessor: ({ row }: any) => <Money value={row.ppc_promocional} />,
  },
  {
    Header: 'Volume Total',
    accessor: ({ row }: any) => <>{row.volume_total || '-'}</>,
  },
  {
    Header: 'Frete',
    accessor: ({ row }: any) => <Money value={row.frete} />,
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <Money value={row.contrato} />,
  },
  {
    Header: 'Outros',
    accessor: ({ row }: any) => <Money value={row.outros} />,
  },
]
