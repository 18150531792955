export const bgColors = [
  '#68D391',
  '#F6E05E',
  '#63B3ED',
  '#F6AD55',
  '#B794F4',
  '#0BC5EA',
  '#F687B3',
  '#76E4F7',
  '#FC8181',
]

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${tooltipItem.dataset.label} ${tooltipItem.raw}%`
        },
      },
    },
    datalabels: {
      formatter: (value: any) => (value > 5 ? value + '%' : ''),
      color: 'black',
    },
  },
  scales: {
    x: { stacked: true },
    y: {
      stacked: true,
      max: 100,
      ticks: { callback: (val: any) => val + '%' },
    },
  },
}

export const defaultData = {
  labels: [''],
  datasets: [
    {
      data: [0],
      backgroundColor: [''],
      borderWidth: 0,
      maxBarThickness: 60,
    },
  ],
}
