import React from 'react';
import { useCallback, useEffect, useState } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import Autocomplete from '../../../../../../components/Autocomplete'
import { FilterIProps, IResponse } from '../types'

const Filters: React.FC<FilterIProps> = ({ level, setRequest }) => {
  const [value, setValue] = useState<IOption[]>([])
  const [options, setOptions] = useState<IOption[]>([])

  const getOptions = useCallback(() => {
    setValue([])
    api.get(`/materials/levels/${level}`).then((res) => {
      setOptions(
        res.data?.data?.level_data?.map((item: IResponse) => ({
          value: item?.level_code,
          label: item?.level_name,
        }))
      )
    })
  }, [level])
  useEffect(() => {
    getOptions()
  }, [getOptions])

  const updateRequest = useCallback(() => {
    const req = { [`level${level}_code`]: value?.map((item) => item?.value) }
    setRequest(req)
  }, [level, setRequest, value])
  useEffect(updateRequest, [updateRequest])

  return (
    <VStack mt="1rem" align="flex-start">
      <Text>Filtrar</Text>
      <Autocomplete
        value={value}
        options={options}
        setValue={setValue}
        isMulti
      />
    </VStack>
  )
}

export default Filters
