import React, { useCallback, useEffect, useState } from 'react'
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { FaInfoCircle as ErrorSvg } from 'react-icons/fa'

import api from '../../../../../services/api'
import Paper from '../../../../../components/Paper'
import Autocomplete from '../../../../../components/Autocomplete'

import Add from './Add'
import { Error } from './styles'
import { Title } from '../../../../../components/PageTitle/styles'
import { IProps } from '../Body/types'
import { IMaterials } from '../types'
import DeleteModal from '../Body/DeleteComponent/DeleteModal'
import { FaTrash as DeleteSvg } from 'react-icons/fa'

interface MaterialOpts {
  item: IMaterials
  label: string
  value: string
}
const Header: React.FC<IProps> = ({ setRows, setCaptain }) => {
  const [sku, setSku] = useState<IOption>({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<MaterialOpts[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [error, setError] = useState<string>()

  useEffect(() => {
    setCaptain(sku.value)
  }, [sku, setCaptain])

  const getSku = useCallback(() => {
    api.get('/relativity-sku/captains').then((res) => {
      setSkuOpt(
        res.data.data.materials.map(
          (item: IMaterials) =>
            +item.captain_code && {
              label: `${+item.captain_code} - ${item.description}`,
              value: +item.captain_code + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const getInfo = useCallback(() => {
    if (!sku.value) return
    api
      .get(`/relativity-sku/materials/${sku.value}`)
      .then((res) => {
        const materials = res.data?.data?.materials
        const matchingMaterials = []
        const nonMatchingMaterials = []

        for (let i = 0; i < materials.length; i++) {
          const item = materials[i]
          if (item.sku === sku.value) {
            matchingMaterials.push(item)
          } else {
            nonMatchingMaterials.push(item)
          }
        }
 
        setRows([...matchingMaterials, ...nonMatchingMaterials])
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }, [sku, setRows])
  useEffect(getInfo, [getInfo])

  return (
    <Paper
      containerStyle={{
        padding: '2rem',
        marginBottom: '2rem',
        overflow: 'visible',
      }}
    >
      <Box w="100%" display={'flex'}>
        <Title>
          <Text fontSize={'16pt'} marginBottom={'5px'}>
            Capitão
          </Text>
        </Title>
      </Box>
      <Box w="100%" justifyContent={'space-between'} display={'flex'}>
        <Box w="30rem" display={'flex'} justifyContent={'space-between'}>
          <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          w={'4rem'}
        >
          <Add />
          {sku.value ? (
            <DeleteSvg
              color={'red'}
              fontSize={'14pt'}
              cursor={'pointer'}
              onClick={() => onOpen()}
            />
          ) : (
            ''
          )}
        </Box>

        {error && (
          <Box w="30rem">
            <Error>
              <ErrorSvg /> {error}
            </Error>
          </Box>
        )}
      </Box>
      <DeleteModal isOpen={isOpen} row={sku} onClose={onClose} />
    </Paper>
  )
}

export default Header
