import { TableCell, TableRow } from '@material-ui/core'

const Row = ({ row, prepareRow }) => {
  prepareRow(row)

  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <TableCell
            key={`${cell.row.id}-${cell.value}`}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
export default Row
