import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
}

const ModalConfirmNewUser: React.FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Usuário cadastrado com sucesso!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Gostaria de cadastrar um novo usuário?</ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onConfirm}>
            Cadastrar Novo
          </Button>
          <Button onClick={onCancel}>Voltar para Usuários</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmNewUser
