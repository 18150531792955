import { useCallback, useEffect, useState } from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'
import { Flex, Text, VStack } from '@chakra-ui/react'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  PointElement,
  LineElement,
} from 'chart.js'

import { defaultData, options } from './constants'

import {
  MOCK_PREDICTED_GRAPH_1,
  MOCK_PREDICTED_GRAPH_2,
  MOCK_PREDICTED_GRAPH_3,
  MOCK_PREDICTED_GRAPH_4,
  MOCK_PREDICTED_GRAPH_5,
  // MOCK_PREDICTED_GRAPH_6,
  // MOCK_PREDICTED_GRAPH_7,
} from '../MOCK_DATA'

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface IProps {
  productId: string
}

const PredictedVSAccomplished: React.FC<IProps> = ({ productId }) => {
  const [ready, setReady] = useState(false)
  const [graphData, setGraphData] = useState(defaultData)

  useEffect(() => {
    setReady(false)
  }, [productId])

  const getData = useCallback(() => {
    if (!productId) return

    const newData = { ...defaultData }

    const days =
      {
        '1': MOCK_PREDICTED_GRAPH_1,
        '2': MOCK_PREDICTED_GRAPH_2,
        '3': MOCK_PREDICTED_GRAPH_3,
        '4': MOCK_PREDICTED_GRAPH_4,
        '5': MOCK_PREDICTED_GRAPH_5,
        // '6': MOCK_PREDICTED_GRAPH_6,
        // '7': MOCK_PREDICTED_GRAPH_7,
      }[productId] || []

    newData.labels = days.map((day: any) =>
      day.date
        .split('-')
        .reverse()
        .join('/')
        .slice(0, day.date.length - 5)
    )

    newData.datasets = [
      {
        label: 'Realizado',
        maxBarThickness: 40,
        borderColor: '#63B3ED',
        backgroundColor: '#63B3ED',
        data: days.map((day: any) => day.previsto),
      },
      {
        label: 'Previsto',
        maxBarThickness: 40,
        borderColor: '#F6AD55',
        backgroundColor: '#F6AD55',

        data: days.map((day: any) => day.realizado),
      },
    ]

    setGraphData(newData)
    setTimeout(() => setReady(true), 0)
  }, [productId])
  useEffect(getData, [getData])

  if (!productId || !ready) return null
  return (
    <VStack
      w="100%"
      h="40rem"
      p="1rem"
      align="flex-start"
      bgColor="#fff"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.200"
    >
      <Text fontWeight="semibold" fontSize="md">
        Previsto vs Realizado
      </Text>
      <Flex h="100%" w="100%">
        <Line options={options} data={graphData} />
      </Flex>
    </VStack>
  )
}

export default PredictedVSAccomplished
