import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import api from '../../../../../../../services/api'

import TUseAdd, { IMaterials } from './types'

const useAdd: TUseAdd = ({ isOpen }) => {
  const toast = useToast()

  const [value, setValue] = useState<IOption>({ label: '', value: '' })
  const [valid, setValid] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [options, setOptions] = useState<IOption[]>([])

  const resetStatesOnCloseModal = useCallback(() => {
    if (!isOpen) {
      setValue({ label: '', value: '' })
      setValid(false)
      setError('')
      setOptions([])
    }
  }, [isOpen])
  useEffect(resetStatesOnCloseModal, [resetStatesOnCloseModal])

  const getInputOptions = useCallback(() => {
    if (!isOpen) return
    api.get('/relativity-sku/non-captains').then((res) => {
      setOptions(
        res.data?.data?.materials?.map(
          (item: IMaterials) =>
            +item.sku && {
              label: `${+item.sku} - ${item.description}`,
              value: +item.sku + '',
            }
        )
      )
      setValid(true)
    })
  }, [isOpen])
  useEffect(getInputOptions, [getInputOptions])

  const onAdd = useCallback(() => {
    const url = `/relativity-sku/captain/${value.value}`
    const req = { sku: +value.value }
    api
      .post(url, req)
      .then(() => {
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
        })
        setTimeout(() => window.location.reload(), 1000)
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
      })
  }, [toast, value.value])

  return {
    error,
    valid,
    value,
    onAdd,
    options,
    setValue,
  }
}

export default useAdd