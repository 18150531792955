import { useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { createFilter } from 'react-select'

import { StyledAutocomplete } from './styles'

interface IProps {
  label?: string
  value: any
  options: IOption[]
  setValue: any
  isMulti?: boolean
}

const Autocomplete: React.FC<IProps> = ({
  label,
  value,
  options,
  setValue,
  isMulti = false,
}) => {
  const loading = useMemo(() => {
    return options.length === 0
  }, [options])

  return (
    <VStack w="100%" align="flex-start">
      {label && <Text>{label}</Text>}
      <StyledAutocomplete
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={(val) => setValue(val)}
        isSearchable
        isClearable={false}
        isLoading={loading}
        placeholder={`${loading ? '. Carregando' : '. Selecione'}...`}
        filterOption={createFilter({ ignoreAccents: false })}
      />
    </VStack>
  )
}

export default Autocomplete
