import styled from 'styled-components'

export const UploadButton = styled.div`
  cursor: pointer;

  position: relative;

  padding: 0.5rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;

  border-radius: 4px;
  background-color: #003b74;

  transition: all 200ms ease-in-out;
`
