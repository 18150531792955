import { Text, Flex, Image, VStack } from '@chakra-ui/react'

import pricemetImg from 'assets/prixsia-logo.png'

import { Container } from './styles'

const Footer: React.FC = () => {
  return (
    <Container>
      <Flex
        w="100%"
        pl={4}
        pr={20}
        py={6}
        gap={4}
        alignItems="center"
        justifyContent="space-between"
        bgColor="#f2f2f2"
      >
        <Image src={pricemetImg} />
        <VStack>
          <Text>Av. Marques de São Vicente 230 Cj. 1214</Text>
          <Text>Barra Funda - CEP. 01139-000 - São Paulo-SP</Text>
        </VStack>
        <Text>+ 55 (11) 3392-6305</Text>
        <Text>Um novo jeito de fazer Pricing.</Text>
      </Flex>
    </Container>
  )
}

export default Footer
