import styled, { css } from 'styled-components'
import { shade } from 'polished'
import { ListItemIcon as MUIListItemIcon, ListItem } from '@material-ui/core'

interface ItemProps {
  active?: string
}

interface CurveProps {
  active: string
}

export const Container = styled.div`
  position: relative;
  z-index: 1;

  svg {
    path {
      stroke: rgba(0, 0, 0, 0.87);
      transition: stroke 0.3s;
    }
  }

  span {
    transition: color 0.3s;
    font-size: 1.2rem;
  }
`

export const Item = styled(ListItem)<ItemProps>`
  transition: background 0.3s;

  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;

  & + div {
    margin-top: 0.6rem;
  }

  ${({ active }) =>
    active === 'true'
      ? css`
          background: #003b74 !important;

          svg {
            path {
              stroke: #fff;
              fill: #fff;
            }
          }

          span {
            color: #fff;
          }
        `
      : css`
          background: none;
        `}

  &:hover {
    svg {
      path {
        stroke: ${({ active }) => (active === 'true' ? '#fff' : '#003b74')};
        fill: ${({ active }) => (active === 'true' ? '#fff' : '#003b74')};
      }
    }

    span {
      color: ${({ active }) => (active === 'true' ? '#fff' : '#003b74')};
    }

    background: ${({ active }) =>
      active === 'true' ? shade(0.3, '#003b74') : ''};

    .curve {
      background: ${({ active }) =>
        active === 'true' ? shade(0.3, '#003b74') : ''};
    }
  }
`

export const ListItemIcon = styled(MUIListItemIcon)`
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;

  svg {
    font-size: 2rem;
  }
`
export const TopCurve = styled.div<CurveProps>`
  ${({ active }) =>
    active === 'true' &&
    css`
      width: 0.6rem;
      height: 0.6rem;
      background: #003b74;
      position: absolute;
      top: -0.6rem;
      left: 0;
      transition: background 0.3s;
      z-index: 1;

      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        border-bottom-left-radius: 10px;
        background: #fff;
        z-index: 2;
      }
    `}
`

export const BottomCurve = styled.div<CurveProps>`
  ${({ active }) =>
    active === 'true' &&
    css`
      width: 0.6rem;
      height: 0.6rem;
      background: #003b74;
      position: absolute;
      bottom: -0.6rem;
      left: 0;
      transition: background 0.3s;

      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        border-top-left-radius: 10px;
        background: #fff;
      }
    `}
`
