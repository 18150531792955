import _ from 'lodash'

import nloop from '../nloop'

import IActions from './types/IActions'
import TNiveisReducer from './types/TNiveisReducer'

const _setOpts: TNiveisReducer = (state, action) => {
  const clone = { ...state }

  nloop.forEach((n) => {
    clone[`${n}opt`] = action.payload[n]
  })

  return _.isEqual(clone, state) ? state : clone
}

const _setNivel: TNiveisReducer = (state, action) => {
  const clone = { ...state }

  nloop.forEach((n) => {
    if (action.payload[n]) clone[n] = action.payload[n]
  })

  return _.isEqual(clone, state) ? state : clone
}

const _actionsDct: IActions = {
  SETOPTS: _setOpts,
  SETNIVEL: _setNivel,
}

const niveisReducer: TNiveisReducer = (state, action) => {
  return _actionsDct[action.type](state, action)
}

export default niveisReducer
