import { useCallback, useEffect, useState } from 'react'
import { HStack, useToast } from '@chakra-ui/react'

import api from '../../../../services/api'
import Options from '../_layouts/Options'

import IRow from './types/IRow'
import Table from './Table'

const limit = 15
const binaryPos = [1, 2, 4, 8, 16]
const parameter = 'margem_mercado'

const MargemMercado = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [level] = useState<number>(4)
  const [levels] = useState<number>(11110)
  const [loading, setLoading] = useState(true)
  const [request, setRequest] = useState<object>({})
  const [clusterId, setClusterId] = useState<string>('')

  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPage] = useState<number>(1)

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [clusterId, request, levels])

  const getRows = useCallback(() => {
    if (!loading || !clusterId) return
    const url = `/market-margins/${clusterId}?page=${page}`

    api
      .post(url, request)
      .then((response) => {
        setRows(response.data?.data?.data?.payload)
        setTotal(response.data?.data?.data?.total)
        setTotalPage(response.data?.data?.data?.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [clusterId, loading, request, toast, page])
  useEffect(getRows, [getRows])

  return (
    <HStack align="flex-start" spacing={6}>
      <Options
        level={level}
        parameter={parameter}
        clusterId={clusterId}
        setRequest={setRequest}
        setClusterId={setClusterId}
      />
      <Table
        rows={rows}
        levels={levels}
        loading={loading}
        clusterId={clusterId}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={total}
        setPage={(newVal) => {
          if (newVal === page) return
          setPage(newVal)
          setLoading(true)
        }}
        totalPages={totalPages}
      />
    </HStack>
  )
}

export default MargemMercado
