import { useCallback, useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

interface IProps {
  end: string
  accepted: number[]
  roundHouse: number
  setExample: React.Dispatch<React.SetStateAction<string>>
}

const values = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const Example: React.FC<IProps> = ({
  end,
  accepted,
  roundHouse,
  setExample,
}) => {
  const base = useMemo(() => {
    return end.replace(/\d/g, '0')
  }, [end])

  const getReverseIndex = useCallback(
    (index: number) => {
      return base.split('').length - index - 1
    },
    [base]
  )

  const formatValue = useCallback(
    (value: number) => {
      const previousValueStr = base
        .split('')
        .map((char, index) => {
          const revIndex = getReverseIndex(index)
          return roundHouse === revIndex ? value + '' : char
        })
        .join('')

      return (+previousValueStr).toFixed(2)
    },
    [base, getReverseIndex, roundHouse]
  )

  const exampleText = useMemo(() => {
    const newExample = values
      .filter((value) => !accepted.includes(value))
      .map((value) => formatValue(value))
      .filter((value) => +value <= +end)
      .join('; ')

    setExample(newExample)

    return newExample
  }, [accepted, end, formatValue, setExample])

  if (!end) return null
  return (
    <VStack align="flex-start">
      <Text>Exemplo</Text>
      <Text>{exampleText}</Text>
    </VStack>
  )
}

export default Example
