import React from 'react'
import { HStack, StackDivider } from '@chakra-ui/react'
import Paper from 'components/Paper'
import Level from './Level'
import Cluster from './Cluster'
import Modules from './Modules'
// import Category from './Category'
import Permissions from './Permissions'
import Approvals from './Approvals'
import { Values } from './Approvals/UseSlider/types'

interface IProps {
  nivel: number
  roles: { id: number; name: string }[]
  active: boolean
  setNivel: React.Dispatch<React.SetStateAction<number>>
  setRoles: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>
  handleChangeSliderValues: (values: Values, index: number) => void
  sliderValues: Values[]
  setClusters: React.Dispatch<React.SetStateAction<string[]>>
}

const Access: React.FC<IProps> = ({
  nivel,
  roles,
  active,
  setNivel,
  setRoles,
  handleChangeSliderValues,
  sliderValues,
  setClusters,
}) => {
  return (
    <Paper
      containerStyle={{
        width: '100%',
        padding: '1rem',
        overflow: 'visible',
        backgroundColor: ` ${active ? '#FFF' : '#FFF5F5'}`,
      }}
    >
      <HStack
        w="100%"
        align="flex-start"
        justify="space-around"
        spacing="1rem"
        divider={<StackDivider />}
      >
        <Modules />
        <Permissions roles={roles} setRoles={setRoles} />
        <Level nivel={nivel} setNivel={setNivel} />
        <Approvals
          handleChangeSliderValues={handleChangeSliderValues}
          sliderValues={sliderValues}
        />
        <Cluster setClusters={setClusters} />
        {/* <Category /> */}
      </HStack>
    </Paper>
  )
}

export default Access
