import styled from 'styled-components'
import { AccordionItem } from '@chakra-ui/react'

export const Container = styled(AccordionItem)`
  color: #003b74;

  input {
    background-color: #fff;
  }

  svg {
    font-size: 1.2rem;
  }
`

export const Main = styled.div`
  height: 4rem;

  button {
    height: 100%;
  }
`
