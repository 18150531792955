import React,{
  useCallback,
  useEffect,
  useState,
  useReducer,
  Fragment,
} from 'react'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from '../../../../../services/api'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'

import IFilters from '../types/IFilters'

import nloop from './nloop'
import { niveisIniState, niveisReducer } from './reducer'
import { ICaptain, IClusters } from '../types/IRow'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Filters: React.FC<IProps> = ({
  filters,
  setFilters,
  setPage,
  setLoading,
}) => {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [niveis, dispatch] = useReducer(niveisReducer, niveisIniState)
  const [clusterId, setClusterId] = useState<IOption[]>([])
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [captainsIdOpt, setCaptainsIdOpt] = useState<IOption[]>([])
  const [captainsId, setCaptainsId] = useState<IOption[]>([])
  const [isFiltering, setIsFiltering] = useState<boolean>(false)

  const setNivel = useCallback((data: { [n: string]: IOption[] }) => {
    dispatch({ type: 'SETNIVEL', payload: data })
  }, [])

  const setValueNivel = useCallback(
    (newVal: IOption[], key: string) => {
      const data: { [n: string]: IOption[] } = {}

      nloop.forEach((n) => {
        if (n > key) return (data[n] = [])
        if (n === key) return (data[n] = newVal)
        data[n] = niveis[n]
      })

      setNivel(data)
    },
    [niveis, setNivel]
  )

  const setDefaultData = useCallback(() => {
    nloop.forEach((n) => {
      setNivel({ [n]: filters[`level${n}`] })
    })

    setCaptainsId(filters?.captain_code)
    setClusterId(filters?.cluster_id)
  }, [filters, setNivel])
  useEffect(setDefaultData, [setDefaultData, isOpen])

  const getNiveis = useCallback(() => {
    isOpen &&
      api.get('/materials/levels/all',).then((res) => {
        const opts: { [n: string]: IOption[] } = {}
        nloop.forEach((n) => {
          opts[n] = res.data?.data?.level_data[`nivel0${n}`].map((v: any) => ({
            label: v?.level_name,
            value: v?.level_code,
          }))
        })
        dispatch({ type: 'SETOPTS', payload: opts })
      })
      .catch((e) => {
        toast({
          title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
          status: 'error',
          isClosable: true,
        });
      });
  }, [isOpen, toast])
  useEffect(getNiveis, [getNiveis])

  const getClusterId = useCallback(() => {
    isOpen &&
      api.get('/clusters').then((res) => {
        setClusterIdOpt(
          res.data.data.clusters.map((v: IClusters) => ({
            label: v?.name,
            value: v?.id,
          }))
        )
      })
      .catch((e) => {
        toast({
          title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
          status: 'error',
          isClosable: true,
        });
      });
  }, [isOpen, toast])
  useEffect(getClusterId, [getClusterId])
  
  const getCaptainsId = useCallback(() => {
    isOpen &&
      api.get('/relativity-sku/captains')
        .then((res) => {
          setCaptainsIdOpt(
            res.data?.data?.materials?.map((v: ICaptain) => ({
              label: v?.description,
              value: v?.captain_code,
            }))
          );
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          });
        });
  }, [isOpen, toast, setCaptainsIdOpt]);
  
  useEffect(() => {
    getCaptainsId();
  }, [getCaptainsId]);
  

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: clusterId,
      captain_code: captainsId,
    }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = niveis[n]
    })
    setIsFiltering(false)

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]?.length > 0) setIsFiltering(true)
    })

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [clusterId, niveis, onClose, setFilters, setLoading, setPage, captainsId])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      captain_code: [],
    }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = []
    })

    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [onClose, setFilters, setLoading, setPage])

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterId}
                options={clusterIdOpt}
                setValue={setClusterId}
                isMulti
              />
              <Text>Sku Capitão</Text>
              <Autocomplete
                value={captainsId}
                options={captainsIdOpt}
                setValue={setCaptainsId}
                isMulti
              />
              {nloop.map((n) => (
                <Fragment key={n}>
                  <Text>Nível 0{n}</Text>
                  <Autocomplete
                    isMulti
                    value={niveis[n]}
                    options={niveis[`${n}opt`]}
                    setValue={(newVal: IOption[]) => setValueNivel(newVal, n)}
                  />
                </Fragment>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClear}>Limpar</Button>
                <Button onClick={onFilter}>Filtrar</Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Filters
