
import api from '../../../services/api'

export const createUserService = async (payload: any) => {
  const url = `access-controll/create-user`

  return api
    .post(url, payload)
    .then((result) => {
      return {
        status: result.status,
        message: 'Usuário cadastrado com sucesso',
      }
    })
    .catch((error) => {
      return {
        status: error.response.data.statusCode,
        message: error.response.data.error.description.message,
      }
    })
}
