import { useCallback, useRef } from 'react'
import moment from 'moment'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'

import IRow from '../../../types/IRow'
import { DeleteButton } from './styles'

interface IProps {
  row: IRow
}

const Delete: React.FC<IProps> = ({ row }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancelRef = useRef(null)

  const onDelete = useCallback(() => {
    api
      .delete(`/market-prices/${row.id}`)
      .then(() => {
        onClose()
        toast({
          title: 'Deletado com sucesso!',
          status: 'warning',
        })
        setTimeout(() => window.location.reload(), 1000)
      })
      .catch((e) => {
        toast({
          title: `${e.response.status} - Erro ao deletar, tente novamente.`,
          status: 'error',
        })
        onClose()
      })
  }, [onClose, row.id, toast])

  return (
    <>
      <DeleteButton onClick={onOpen} />
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar: {row.id} - {row.name} - {row.type} -{' '}
              {moment(row.date).zone(0).format('DD/MM/YYYY')}
            </AlertDialogHeader>
            <AlertDialogBody>
              Essa ação não poderá ser desfeita!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justify="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  onClick={onDelete}
                  containerStyle={{ backgroundColor: '#D20404' }}
                >
                  Deletar
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Delete
