import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  w?: string | number
  title?: any
  body?: any
  footer?: any
}

const Modal: React.FC<IProps> = ({
  isOpen,
  onClose,
  w = '40rem',
  title,
  body,
  footer,
}) => {
  return (
    <ChakraModal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minW={w}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {body && <ModalBody>{body}</ModalBody>}
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
