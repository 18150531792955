import { useMemo } from 'react'
import moment from 'moment'

import TableBackend from 'components/TableBackend'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'
import Options from './Options'

interface IProps extends IPaginationOptions {
  rows: IRow[]
}

const Table: React.FC<IProps> = ({
  rows,
  page,
  limit,
  total,
  setPage,
  totalPages,
}) => {
  const cols = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <span>{moment(row.date).zone(0).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: '',
        accessor: ({ row }: any) => <Options row={row} />,
      },
    ]
  }, [])

  return (
    <TableBackend
      rows={rows}
      cols={cols}
      page={page}
      limit={limit}
      total={total}
      setPage={setPage}
      totalPages={totalPages}
    />
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
