import axios from 'axios'
import { useToast } from '@chakra-ui/react'
import { createBrowserHistory } from 'history'

const baseURL = process.env.REACT_APP_API_URL ?? 'http://localhost:3333/api' 
const timeout = 180 * 1000

const api = axios.create({
  baseURL,
  timeout,
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('@Prixsia:token')
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, 'error')
    if (error?.response.data.statusCode === 403) {
      useToast({
        title: 'Você não possui autorização para realizar essa ação.',
        status: 'error',
        isClosable: true,
      })
    }

    if (error?.response.data.statusCode === 401) {
      localStorage.clear()
      setTimeout(() => {
        window.location.href = '/login'
      }, 5000)
      useToast({
        title: 'Seu token foi expirado, faça login novamente!',
        status: 'error',
        isClosable: true,
      })
    }

    return Promise.reject(error)
  }
)

export default api
