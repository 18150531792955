import styled from 'styled-components'
import { Table as BaseTable } from '@chakra-ui/react'

export const TableStyled = styled(BaseTable)`
  thead tr {
    background-color: #f2f3f5;
  }

  th,
  td {
    width: auto;
    max-width: 500px;
    padding: 0.5rem 0.75rem;

    color: #003b74;
    border-bottom: 0;

    white-space: nowrap;
  }

  th {
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
  }

  th,
  td,
  input,
  select {
    font-size: 1rem !important;
  }
`
