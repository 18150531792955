import React, { useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import Button from '../../../../../../../components/Button'
import api from '../../../../../../../services/api'
import { IProps } from './types'

const DeleteModal: React.FC<IProps> = ({ isOpen, row, onClose }) => {
  const toast = useToast()
  const cancelRef = useRef<null>(null)

  const onDelete = useCallback(() => {
    api
      .delete(`/relativity-sku/captain/${row.value}`)
      .then(() => {
        onClose()
        toast({
          title: 'Deletado com sucesso!',
          status: 'warning',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 1000)
      })
      .catch((error) => {
        toast({
          title: `Erro ao deletar o capitão: ${error.message} ` ,
          status: 'error',
          isClosable: true,
        })
      })
  }, [onClose, row.value, toast])
  console.log(row)
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack alignItems="flex-start" fontSize="md" spacing={0.5}>
                <Text color="#D20404">Essa ação não poderá ser desfeita!</Text>
                <Text fontSize={'9pt'} color="#D20404">A relação com seus filhos será apagada</Text>
                <Text fontSize={'13pt'}> Capitão: {row?.label}</Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  containerStyle={{ backgroundColor: '#D20404' }}
                  onClick={onDelete}
                >
                  Deletar
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteModal
