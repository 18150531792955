import { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import PrivateLayout from 'layouts/PrivateLayout'

interface IProps {
  user: boolean
}

const PrivateRoute: React.FC<IProps> = ({ user }) => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const [validated, setValidated] = useState(false)

  const onPrivateNavigate = useCallback(() => {
    const token = localStorage.getItem('@Prixsia:token')

    if (!user || !token) {
      localStorage.clear()
      navigate('/login')
    }
  }, [navigate, user])

  useEffect(() => {
    onPrivateNavigate()
    setValidated(true)
  }, [onPrivateNavigate, pathname])

  if (!validated) return null
  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  )
}

export default PrivateRoute
