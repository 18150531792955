import { HStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import api from 'services/api'

import Card from './Card'
import IData from './types/IData'

interface IProps {
  getBigData: boolean
}

const Cards: React.FC<IProps> = ({ getBigData }) => {
  const { id } = useParams()

  const [data, setData] = useState<IData[]>([])

  const getData = useCallback(() => {
    api.get(`/discount-scenery/${id}/big-numbers`).then((res) => {
      const info = res.data.data.big_numbers[0]

      setData([
        {
          title: 'Investimento',
          investimento: info.investimento,
          volume_estimado: info.volume_estimado,
          faturament_estimado: info.faturament_estimado,
          color: 'blue',
        },
      ])
    })
  }, [id])
  useEffect(getData, [getData, getBigData])

  return (
    <HStack w="100%" spacing="1rem" mb="1rem" align="flex-end">
      {data.map((info) => (
        <Card key={info.title} info={info} />
      ))}
    </HStack>
  )
}

export default Cards
