import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from 'components/LoadingScreen'

const AppEntry: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/login')
  }, [navigate])

  return <LoadingScreen />
}

export default AppEntry
