import { memo } from 'react'
import { FaPlusCircle as AddSvg } from 'react-icons/fa'
import { Flex, Text, useDisclosure, VStack } from '@chakra-ui/react'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'

import useAdd from './useAdd'

const Add = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { error, valid, value, onAdd, options, setValue } = useAdd({ isOpen })

  return (
    <>
      <Text fontSize="1.8rem" color="#003b74" cursor="pointer"  onClick={onOpen}>
        <AddSvg  />
      </Text>
      <Modal
        title="Adicionar"
        isOpen={isOpen}
        onClose={onClose}
        body={
          <VStack w="100%" align="flex-start">
            <Text>Selecione um novo código capitão</Text>
            <Autocomplete value={value} options={options} setValue={setValue} />
            {error && <Text color="red.500">{error}</Text>}
          </VStack>
        }
        footer={
          <Flex w="100%" justify="space-between">
            <Button onClick={onClose}>Cancelar</Button>
            <Button disable={!valid} onClick={onAdd}>
              Adicionar
            </Button>
          </Flex>
        }
      />
    </>
  )
}

export default memo(Add)