import React from 'react';
import { AiOutlinePlusCircle as AddSvg } from 'react-icons/ai'
import { HStack, Box } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete';
import {
    FaInfoCircle as ErrorSvg,
    FaPlusCircle as CreateSvg,
    FaTimesCircle as CancelSvg,
  } from 'react-icons/fa'
import TAddComponent from './types'
import { Add, Error, Options } from '../styles'
import { addColumns } from '../constants'

const AddComponent :React.FC<TAddComponent> = ({
  isAdding,
  setIsAdding,
  sku,
  skuOpt,
  setSku,
  error,
  onCancel,
  onAdd,
}) => {
  if (!isAdding) {
    return (
      <Add onClick={setIsAdding}>
        <AddSvg />
      </Add>
    );
  }

  return (
    <>
      <HStack
        spacing={4}
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#f2f3f5"
        borderRadius="4px"
      >
        {addColumns?.map((column) => (
          <Box w="14rem" key={column.Header}>
            {column.Header}
          </Box>
        ))}
      </HStack>
      <HStack spacing={4} pl="1rem">
        <Box w="35rem">
          <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
        </Box>
        <Box w="20rem">
          {error && (
            <Error>
              <ErrorSvg /> {error}
            </Error>
          )}
        </Box>
        <Box w="100%">
          <Options>
            <CancelSvg onClick={onCancel} />
            <CreateSvg onClick={onAdd} />
          </Options>
        </Box>
      </HStack>
    </>
  );
};

export default AddComponent;