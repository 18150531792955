import Money from './Money'
import Percent from './Percent'
import Checkbox from './Checkbox'
import Desconto from './Desconto'
import PPVFinal from './PPVFinal'
import QuantVenda from './QuantVenda'

export const cols = [
  {
    Header: '',
    accessor: ({ row, updateCell, toggleBigData }: any) => (
      <Checkbox
        row={row}
        updateCell={updateCell}
        toggleBigData={toggleBigData}
      />
    ),
  },
  { Header: 'Cluster', accessor: 'cluster_name' },
  { Header: 'Canais', accessor: 'channel_name' },
  { Header: 'Cliente', accessor: 'client_name' },
  { Header: 'Cód. Capitão', accessor: 'sku_capitao' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Nível 1', accessor: 'nivel01' },
  { Header: 'Nível 2', accessor: 'nivel02' },
  { Header: 'Nível 3', accessor: 'nivel03' },
  {
    Header: 'Preço Tabela Atual',
    accessor: ({ row }: any) => <Money value={row.ppv_atual} />,
  },
  {
    Header: 'Custo Média Atual',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'Mrg Atual',
    accessor: ({ row }: any) => <Percent value={row.margem_atual} />,
  },
  {
    Header: 'Desconto',
    accessor: ({
      row,
      rowIndex,
      updateRow,
      discountInputsRef,
      toggleBigData,
    }: any) => (
      <Desconto
        row={row}
        rowIndex={rowIndex}
        updateRow={updateRow}
        discountInputsRef={discountInputsRef}
        toggleBigData={toggleBigData}
      />
    ),
  },
  {
    Header: 'PPV Novo',
    accessor: ({
      row,
      rowIndex,
      updateRow,
      ppvInputsRef,
      toggleBigData,
    }: any) => (
      <PPVFinal
        row={row}
        rowIndex={rowIndex}
        updateRow={updateRow}
        ppvInputsRef={ppvInputsRef}
        toggleBigData={toggleBigData}
      />
    ),
  },
  { Header: 'Histórico Quant.', accessor: 'volume_historico' },
  {
    Header: 'Quant. Estimada Venda',
    accessor: ({
      row,
      rowIndex,
      updateRow,
      quantVendaInputsRef,
      toggleBigData,
    }: any) => (
      <QuantVenda
        row={row}
        rowIndex={rowIndex}
        updateRow={updateRow}
        quantVendaInputsRef={quantVendaInputsRef}
        toggleBigData={toggleBigData}
      />
    ),
  },
  {
    Header: 'Investimento',
    accessor: ({ row }: any) => <Money value={row.investimento} />,
  },
  {
    Header: 'Faturamento',
    accessor: ({ row }: any) => <Money value={row.faturamento} />,
  },
]
