import React, { useCallback, useState } from 'react'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Select,
  Text,
  Checkbox,
  HStack,
} from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'
import Button from '../../../../../../components/Button'

const options = [
  { label: 'Selecione...', value: '' },
  { label: 'MÍNIMO', value: 'Mínimo' },
  { label: 'MÉDIA', value: 'Média' },
  { label: 'MÁXIMO', value: 'Máximo' },
  { label: 'MODA', value: 'Moda' },
]

const CalculationType: React.FC<IProps> = ({ row }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState<string>(row?.calculation_type)
  const [selectedValue, setSelectedValue] = useState<string>()
  const [repeatedValues, setRepeatedValues] = useState<string[]>([])
  const toast = useToast()

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics/'
      const req = {
        cluster_id: row?.cluster_id,
        calculation_type: newVal,

        captain_code: row?.sku,
      }

      api
        .put(url, req)
        .then((res) => {
          const repeatedValues = res.data?.data?.repeatedPrices
          if (repeatedValues?.length > 1) {
            setRepeatedValues(repeatedValues)
            setSelectedValue('')
            onOpen()
          }
        })
        .catch((error) => {
          toast({
            status: 'error',
            title: `Erro, tente novamente: ${error?.message}`,
          })
          setValue(row?.calculation_type)
        })
    },
    [row, toast, onOpen]
  )
  const onSave = useCallback(() => {
    const request = {
      medianValue: selectedValue
    }
    api.post('/rota', request).then(() => {
      toast({
        title: 'Salvo com sucesso!',
        status: 'success',
        isClosable: true,
      })
    })
    .catch((error)=>{
      toast({
        title:  `Erro, tente novamente: ${error?.message}`,
        status: 'error',
        isClosable: true,
      })
    })
  }, [toast, selectedValue])
  return (
    <>
      <Select
        w="8rem"
        value={value}
        onChange={onChange}
        disabled={row?.is_pricepoint}
      >
        {options?.map((option) => (
          <option key={option?.label} value={option?.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="50rem">
          <ModalHeader color={'red'}>
            {"Múltiplos valores para 'Moda' existentes"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Text fontSize={'12pt'}>
              Escolha um valor para o cálculo da Moda:
            </Text>
            {repeatedValues?.map((item) => (
              <HStack key={item}>
                <Checkbox
                  size="lg"
                  isChecked={selectedValue === item}
                  onChange={() => {
                    setSelectedValue(item)
                  }}
                >
                  <Text fontSize="12pt">{item}</Text>
                </Checkbox>
              </HStack>
            ))}
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onSave}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CalculationType
