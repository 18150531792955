import { useCallback } from 'react'

interface IProps {
  value: string | number
}

const Money: React.FC<IProps> = ({ value }) => {
  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    return newVal
  }, [])

  if (value === '-') return <>{value}</>
  return <>{value && `R$ ${localeNumber(value + '')}`}</>
}

export default Money
