import { useCallback, useState } from 'react'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Flex,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Modal from 'components/Modal'
import Button from 'components/Button'
import LoadingModalButton from 'components/LoadingModalButton'

const SendToApproveButton = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onConfirm = useCallback(() => {
    const url = '/requests/create'
    const req = { id: +id!, type, date_initial: '', date_final: '' }

    return api
      .post(url, req)
      .then(() => {
        navigate('/pricebase')
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro ao enviar, tente novamente.',
        })
      })
  }, [ id, navigate, toast, type])

  return (
    <>
      <Button onClick={onOpen}>Enviar Para Aprovação</Button>
      <Modal
        w="20rem"
        isOpen={isOpen}
        onClose={onClose}
        title="Enviar Para Aprovação"
        body={
          <></>
        }
        footer={
          <Flex w="100%" justify="space-between">
            <Button onClick={onClose}>Cancelar</Button>
            <LoadingModalButton
              promise={onConfirm}
              message="Enviando, por favor aguarde..."
            >
              Confirmar
            </LoadingModalButton>
          </Flex>
        }
      />
    </>
  )
}

export default SendToApproveButton
