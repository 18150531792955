import { useCallback } from 'react'

interface IProps {
  value: string | number
}

const Percent: React.FC<IProps> = ({ value }) => {
  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',0'
    return newVal
  }, [])

  if (value === '-') return <>{value}</>
  return <>{value && `${localeNumber(value + '')}%`}</>
}

export default Percent
