import Money from './Money'
import Percent from './Percent'
import Checkbox from './Checkbox'
import PPVFinal from './PPVFinal'
import Expander from './Expander'
import { Flex, HStack, Text } from '@chakra-ui/react'
import HeaderCheckbox from './HeaderCheckbox'

const hues = [210, 30, 250, 135]
const styles = (hue: number) => ({
  th: () => ({ bgColor: `hsla(${hue}, 30%, 91%, 1)` }),
  td: ({ rowIndex }: any) => ({
    bgColor:
      rowIndex & 1 ? `hsla(${hue}, 20%, 95%, 1)` : `hsla(${hue}, 25%, 98%, 1)`,
  }),
})

export const colsSimple = [
  {
    Header: ({ filters, getRows, allChecked, setAllChecked }: any) => (
      <HeaderCheckbox
        filters={filters}
        getRows={getRows}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
    ),
    accessor: ({
      row,
      updateCell,
      showSubRow,
      toggleSubRow,
      toggleBigData,
    }: any) => (
      <HStack>
        <Expander showSubRow={showSubRow} toggleSubRow={toggleSubRow} />
        <Checkbox
          row={row}
          updateCell={updateCell}
          toggleBigData={toggleBigData}
        />
      </HStack>
    ),
  },
  { Header: 'Cluster', accessor: 'cluster_name' },
  { Header: 'Fábrica', accessor: 'factory_name' },
  { Header: 'UF Destino', accessor: 'uf_destino' },
  { Header: 'Canais', accessor: 'channel_name' },
  { Header: 'Cód. Capitão', accessor: 'captain_code' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Classificação do Item', accessor: 'classificacao_produto' },
  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'PPV Atual',
    accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_novo} />,
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.mrg_ppv_objetiv * 100} />,
  },
  {
    Header: 'Mrg PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.margem_ppv_novo} />,
  },
  {
    Header: 'Novo PPC',
    accessor: ({ row }: any) => <Money value={row.novo_ppc} />,
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.mrg_mercado} />,
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice_final} />,
  },
]

export const colsDetailed = [
  {
    Header: ({ filters, getRows, allChecked, setAllChecked }: any) => (
      <HeaderCheckbox
        filters={filters}
        getRows={getRows}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
    ),
    accessor: ({
      row,
      updateCell,
      showSubRow,
      toggleSubRow,
      toggleBigData,
    }: any) => (
      <HStack>
        <Expander showSubRow={showSubRow} toggleSubRow={toggleSubRow} />
        <Checkbox
          row={row}
          updateCell={updateCell}
          toggleBigData={toggleBigData}
        />
      </HStack>
    ),
  },
  { Header: 'Cluster', accessor: 'cluster_id' },
  { Header: 'Cluster Nome', accessor: 'cluster_name' },
  { Header: 'Canal', accessor: 'channel_name' },
  { Header: 'Fábrica', accessor: 'factory_name' },
  { Header: 'UF Destino', accessor: 'uf_destino' },
  { Header: 'Cod Capitão', accessor: 'captain_code' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Nível 1', accessor: 'level1_name' },
  { Header: 'Nível 2', accessor: 'level2_name' },
  { Header: 'Nível 3', accessor: 'level3_name' },
  { Header: 'Nível 4', accessor: 'level4_name' },
  { Header: 'Nível 5', accessor: 'level5_name' },
  { Header: 'Classificação Item', accessor: 'classificacao_item' },
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>PPC Base</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 1 - Preço Mercado e Índice
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.ppc_base} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Indice',
    accessor: ({ row }: any) => <Percent value={row.indice_meta} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC Indice',
    accessor: ({ row }: any) => <Money value={row.ppc_index} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Relatividade SKU',
    accessor: ({ row }: any) => <Percent value={row.relatividade_sku} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC Relativo',
    accessor: ({ row }: any) => <Money value={row.ppc_relativo} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.mrg_mercado} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC Sem Margem',
    accessor: ({ row }: any) => <Money value={row.ppc_s_mrg} />,
    styles: styles(hues[0]),
  },
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Tipo Tributação</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 2 - Impostos Cliente e Relatividade Canal
        </Text>
      </Flex>
    ),
    accessor: 'tipo_imposto',
    styles: styles(hues[1]),
  },
  {
    Header: 'PIS+COFINS Destino',
    accessor: ({ row }: any) => <Percent value={row.pis_cofins_destino} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'ICMS Destino',
    accessor: ({ row }: any) => <Percent value={row.icms_destino} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'MVA',
    accessor: ({ row }: any) => <Percent value={row.mva} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'ICMS ST',
    accessor: ({ row }: any) => <Percent value={row.icms_st} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.mrg_ppv_objetiv * 100} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'Relat. Canal',
    accessor: ({ row }: any) => <Percent value={row.relat_canal * 100} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'Custo Varejo',
    accessor: ({ row }: any) => <Money value={row.custo_varejo ?? '0'} />,
    styles: styles(hues[1]),
  },
  // 3
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Margem Mín</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 3 - Impostos Origem e Preço Sugestão
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.mrg_min} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Líq. Mín',
    accessor: ({ row }: any) => <Money value={row.ppv_liq_min} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'Margem Máx',
    accessor: ({ row }: any) => <Percent value={row.mrg_max * 100} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Líq. Máx',
    accessor: ({ row }: any) => <Money value={row.ppv_liq_max} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Líq. Atual',
    accessor: ({ row }: any) => <Money value={row.ppv_liq_atual} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_novo} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PIS/COFINS Origem',
    accessor: ({ row }: any) => <Percent value={row.pis_cofins_origem} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'ICMS Origem',
    accessor: ({ row }: any) => <Percent value={row.icms_origem} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'IPI',
    accessor: ({ row }: any) => <Percent value={row.ipi} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Bruto Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_bruto_novo} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'Margem PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.mrg_ppv_novo} />,
    styles: styles(hues[2]),
  },
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Novo PPC S/ Mrg</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 4 - PPV Para PPC Novo
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.novo_ppc_s_mrg} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Novo PPC',
    accessor: ({ row }: any) => <Money value={row.novo_ppc} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Desconto',
    accessor: ({ row }: any) => <Percent value={row.desconto} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Novo PPC Desconto',
    accessor: ({ row }: any) => <Money value={row.novo_ppc_c_desc} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice_final} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Volume Total',
    accessor: ({ row }: any) => <>{row.volume_total || '-'}</>,
    styles: styles(hues[3]),
  },
  {
    Header: 'Desconto Alçada',
    accessor: ({ row }: any) => <>{row.desc_alcada || '-'}</>,
    styles: styles(hues[3]),
  },
  {
    Header: 'PPV Bruto Novo + Desc',
    accessor: ({ row }: any) => <>{row.ppc_bruto_novo_c_desc || '-'}</>,
    styles: styles(hues[3]),
  },
  {
    Header: 'PPV Lid. Novo + Desc',
    accessor: ({ row }: any) => <>{row.ppv_liq_novo_c_desc || '-'}</>,
    styles: styles(hues[3]),
  },
  {
    Header: 'Recuperação Impostos',
    accessor: ({ row }: any) => <Percent value={row.recup_impostos} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Comissões',
    accessor: ({ row }: any) => <Percent value={row.comissoes} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Bonificações',
    accessor: ({ row }: any) => <Percent value={row.bonificacoes} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Investimentos em $ - Total',
    accessor: ({ row }: any) => <Money value={row.investimento_rs} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Investimentos Trade %',
    accessor: ({ row }: any) => <Percent value={row.investimento_pc} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <>{row.contrato || '-'}</>,
    styles: styles(hues[3]),
  },
  {
    Header: 'Frete',
    accessor: ({ row }: any) => <Money value={row.frete} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Frete',
    accessor: ({ row }: any) => <Money value={row.frete} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Lucro Bruto + Recup',
    accessor: ({ row }: any) => <Money value={row.lucro_bruto_c_recup_imp} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Lucro Bruto %',
    accessor: ({ row }: any) => <Percent value={row.lucro_bruto_pc} />,
    styles: styles(hues[3]),
  },
]
