import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@chakra-ui/react'

import api from '../../../../services/api'
import Paper from '../../../../components/Paper'
import LoadingScreen from '../../../../components/LoadingScreen'

import Table from './Table'
import IRow from './types/IRow'
import IPricePointOpt from './types/IPricePointOpt'
import Filters from './Filters'
import { IFilters } from './Filters/types'

const limit = 15

const defaultOption = {
  label: 'Selecione...',
  value: '',
}

const EstrategiaDePosicionamento = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [channels, setChannels] = useState<IOption[]>([])

  const [eanOpt, setEanOpt] = useState<IOption[]>([])
  const [priceTypeOpt, setPriceTypeOpt] = useState<IPricePointOpt>(
    {} as IPricePointOpt
  )
  const [filters, setFilters] = useState<IFilters>({
    values: {
      cluster_id: [],
      captain_code: [],
      channel_code: [],
    },
    is_pricepoint: 0,
  })
  const [isFiltering, setIsFiltering] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPage] = useState<number>(1)

  const getEanOpt = useCallback(() => {
    api
      .get('/market-prices/materials/descriptions')
      .then((res) => {
        setEanOpt([
          defaultOption,
          ...res.data.data.map((item: IRow) => ({
            label: item?.nivel05,
            value: item?.nivel05,
          })),
        ])
      })
      .catch((error) => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: `Erro ao carregar dados, tente novamente: ${error.message}`,
            status: 'error',
          })
        }
      })
  }, [toast])
  useEffect(getEanOpt, [getEanOpt])

  const getPriceTypeOptions = useCallback(() => {
    api
      .get('/politics/calculation-type/options')
      .then((res) => {
        const pricepoint = res.data?.data?.pricepoint
          ? [
              defaultOption,
              ...Object.keys(res.data?.data?.pricepoint).map((key: string) => ({
                label: res.data?.data?.pricepoint[key],
                value: key,
              })),
            ]
          : []

        const not_pricepoint = [
          defaultOption,
          ...(res.data?.data?.not_pricepoint?.map?.((item: string) => ({
            label: item,
            value: item,
          })) || []),
        ]

        setPriceTypeOpt({ pricepoint, not_pricepoint })
      })
      .catch((error) => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: `Erro ao carregar dados, tente novamente: ${error.message}`,
            status: 'error',
          })
        }
      })
  }, [toast])
  useEffect(getPriceTypeOptions, [getPriceTypeOptions])

  const getRows = useCallback(() => {
    const req: { [key: string]: string[] | number } = {
      page,
      limit,
      is_pricepoint: filters?.is_pricepoint,
    }

    Object.keys(filters.values).forEach((key) => {
      req[key] = filters.values[key]?.map((v: IOption) => v.value) || []
    })

    api
      .post('/politics', req)
      .then((response) => {
        setRows(
          response.data?.data?.politics?.map((row: IRow) => ({
            ...row,
            start_date: moment(row.start_date).zone(0).format('YYYY-MM-DD'),
            end_date: moment(row.end_date).zone(0).format('YYYY-MM-DD'),
          }))
        )
        setTotal(response.data?.data?.total)
        setTotalPage(response.data?.data?.totalPages)
        setLoading(false)
      })
      .catch((error) => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: `Erro ao carregar dados, tente novamente: ${error.message}`,
            status: 'error',
          })
        }
      })
  }, [page, toast, filters])
  useEffect(getRows, [getRows])

  const getChannels = useCallback(() => {
    api
      .get('/channels')
      .then((res) => {
        setChannels([
          defaultOption,
          ...res.data.data.channels.map((item: IRow) => ({
            label: item.channel_name,
            value: item.channel_code,
          })),
        ])
      })
      .catch((error) => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: `Erro ao carregar dados, tente novamente: ${error.message}`,
            status: 'error',
          })
        }
      })
  }, [toast])
  useEffect(getChannels, [getChannels])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Filters
        setPage={setPage}
        setLoading={setLoading}
        filters={filters}
        setIsFiltering={setIsFiltering}
        isFiltering={isFiltering}
        setFilters={setFilters}
      />
      <Table
        rows={rows}
        channels={channels}
        eanOpt={eanOpt}
        priceTypeOpt={priceTypeOpt}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newPage) => {
          setLoading(true)
          setPage(newPage)
        }}
      />
    </Paper>
  )
}

export default EstrategiaDePosicionamento
