import { HStack, Radio, RadioGroup, Text } from '@chakra-ui/react'

interface IProps {
  type: string
  setType: React.Dispatch<React.SetStateAction<string>>
}

const PriceType: React.FC<IProps> = ({ type, setType }) => {
  return (
    <RadioGroup value={type} onChange={setType}>
      <Text>Tipo de Preço</Text>
      <HStack align="flex-start" spacing="1rem">
        <Radio value="TABELA">Tabela</Radio>
        <Radio value="DESCONTO">Desconto</Radio>
      </HStack>
    </RadioGroup>
  )
}

export default PriceType
