import { ChakraProvider } from '@chakra-ui/react'

import chakraUITheme from './chakraUITheme'
import materialUITheme from './materialUITheme'

interface Props {
  children: Children
}

const GlobalStylesProvider = materialUITheme(ChakraProvider)

const StylesProvider: React.FC<Props> = ({ children }) => {
  return (
    <GlobalStylesProvider theme={chakraUITheme}>
      {children}
    </GlobalStylesProvider>
  )
}

export default StylesProvider
