import { useMemo } from 'react'

import { Container } from './styles'

interface IProps {
  table: string
  setInputs: React.Dispatch<React.SetStateAction<any>>
}

const Header: React.FC<IProps> = ({ table, setInputs }) => {
  const component = useMemo(() => {
    return {
      'Relatividade de Produtos': null,
      'Relatividade de Canais': null,
      'SKU X Capitão': null,
      Investimento: null,
      'IP Target': null,
      'Margem Min & Max': null,
      'Range de Preço': null,
      Materiais: null,
    }[table]
  }, [table])

  return <Container>{component}</Container>
}

export default Header
