import api from '../../../services/api'

export interface IDataApproveRefectRequest {
  ids: number[]
  type: string
  reason: string
}

export const handleApproveRefectService = async (
  payload: IDataApproveRefectRequest
) => {
  return api
    .post(`/requests/approve-reject`, payload)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return {
        status: error.response.data.statusCode,
        message: error.response.data.error.description.message,
      }
    })
}
