import { useCallback } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import Paper from 'components/Paper'
import scenValue from 'utils/scenValue'

import IData from '../types/IData'

interface IProps {
  info: IData
}

const Card: React.FC<IProps> = ({ info }) => {
  const invalid = useCallback((value: string | number) => {
    return +(value + '').replace(',', '.') ? false : '-'
  }, [])

  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    return newVal
  }, [])
  return (
    <Paper containerStyle={{ width: '100%', maxWidth: '30rem' }}>
      <Flex flexDir="column" align="center" color="#003b74">
        <Text fontSize="md">{info.title}</Text>
        <Text color={`${info.color}.500`} fontSize="5xl" fontWeight="bold">
          {invalid(info.investimento) ||
            `R$ ${localeNumber(scenValue(info.investimento))}`}
        </Text>
        <Flex w="100%" justify="space-between">
          <Text>Volume Estimado</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.volume_estimado) ||
              `R$ ${localeNumber(scenValue(info.volume_estimado))}`}
          </Text>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text>Faturamento estimado</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.faturament_estimado) ||
              `R$ ${localeNumber(scenValue(info.faturament_estimado))}`}
          </Text>
        </Flex>
      </Flex>
    </Paper>
  )
}

export default Card
