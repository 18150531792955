import React, { useCallback, useState } from 'react'
import { Input } from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'
import moment from 'moment'

const DateEnd: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState<string>(row?.end_date)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = value
      setValue(e.target.value)

      if (row?.end_date && moment(e.target.value).isBefore(row?.end_date)) {
        return setValue(current)
      }

      const url = '/politics'
      const req = {
        cluster_id: row?.cluster_id,
        captain_code: row?.sku,
        end_date: e.target.value,
      }

      api.put(url, req).catch(() => {
        setValue(row?.end_date)
      })
    },
    [row.end_date, row.sku, value, row.cluster_id]
  )

  return (
    <Input
      w="12rem"
      type="date"
      value={value}
      onChange={onChange}
      onKeyDown={(e) => e.preventDefault()}
    />
  )
}

export default DateEnd
