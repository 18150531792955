import { FaUsers as UsersSvg } from 'react-icons/fa'
import PageTitle from 'components/PageTitle'
import { Container } from './styles'

const Header: React.FC = () => {
  return (
    <Container>
      <PageTitle
        icon={<UsersSvg />}
        title="Controle de Acesso"
        linkLabel="Novo Usuário"
      />
    </Container>
  )
}

export default Header
