import { Flex } from '@chakra-ui/react'

interface PaperProps {
  children: Children
  containerStyle?: object
}

const Paper: React.FC<PaperProps> = ({ children, containerStyle = {} }) => {
  return (
    <Flex
      p="1rem"
      pos="relative"
      flexDir="column"
      bgColor="white"
      overflow="auto"
      style={containerStyle}
    >
      {children}
    </Flex>
  )
}

export default Paper
