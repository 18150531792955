import React, { useCallback, useState } from 'react'
import { Checkbox } from '@chakra-ui/react'

import api from '../../../../../../services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  updateCell: (accessor: string, newValue: boolean) => void
}

const Pricepoint: React.FC<IProps> = ({ row, updateCell }) => {
  const [value, setValue] = useState<boolean>(row?.is_pricepoint)

  const onChange = useCallback(() => {
    const newValue = !value

    setValue(newValue)
    updateCell('is_pricepoint', newValue)

    const url = '/politics'
    const req = {
      cluster_id: row?.cluster_id,
      is_pricepoint: newValue,

      captain_code: row?.sku,
    }

    api.put(url, req).catch(() => {
      setValue(!newValue)
      updateCell('is_pricepoint', !newValue)
    })
  }, [row.cluster_id, updateCell, value, row.sku])

  return <Checkbox size="lg" isChecked={value} onChange={onChange} />
}

export default Pricepoint
