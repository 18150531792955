import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import jwt from 'jwt-decode'
import { IUser } from 'domain/login/IUser'

interface IUserContext {
  user: IUser | undefined
  loading: boolean
  isAdmin: boolean
  isZMaster: boolean
  setUser: () => void
  clearUser: () => void
  setLoading: (value: boolean) => void
}

const initialValue: IUserContext = {
  user: undefined,
  loading: false,
  isAdmin: false,
  isZMaster: false,
  setUser: () => undefined,
  clearUser: () => undefined,
  setLoading: () => undefined,
}

export const UserContext = createContext<IUserContext>(initialValue)

interface UserContextProviderProps {
  children: React.ReactNode
}
const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<IUser>()
  const [isLoading, toggleLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isZMaster, setIsZMaster] = useState(false)

  const decodeToken = (): IUser | undefined => {
    const token = localStorage.getItem('@Prixsia:token')
    if (token) {
      return jwt(token)
    }
  }

  const setUser = useCallback(() => {
    const tokenDecoded = decodeToken()
    if (tokenDecoded) {
      const { name, profile } = tokenDecoded
      setIsAdmin(profile.toUpperCase() === 'PRIXSIA')
      setIsZMaster(name.toUpperCase() === 'ZMASTER')
      setState(tokenDecoded)
    }
  }, [])

  const clearUser = useCallback(() => {
    setState(undefined)
    setIsAdmin(false)
  }, [])

  const setLoading = useCallback((value = false) => {
    toggleLoading(value)
  }, [])

  useEffect(() => {
    setUser()
  }, [setUser])

  const value = useMemo(() => {
    return {
      user: state,
      setUser,
      clearUser,
      setLoading,
      loading: isLoading,
      isAdmin,
      isZMaster,
    }
  }, [state, setUser, clearUser, setLoading, isLoading, isAdmin, isZMaster])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default React.memo(UserContextProvider)
