import { AxiosResponse } from 'axios'
import api from '../../services/api'

export interface ICluster {
  id: number
  name: string
  factory_code: string | null
  factory_name: string | null
  factory_uf: string | null
}

interface ApiResponse {
  statusCode: number
  message: string
  data: {
    clusters: ICluster[]
  }
  error: null
}

export const getClustersListService = async () => {
  const url = `clusters/all`

  return api
    .get(url)
    .then((response: AxiosResponse<ApiResponse>) => {
      const clusters: ICluster[] = response.data.data.clusters
      return clusters
    })
    .catch(() => {
      return []      
    })
}
