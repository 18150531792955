import { HStack, VStack, Text, Input, Checkbox } from '@chakra-ui/react'
import Paper from 'components/Paper'

interface IProps {
  name: string
  email: string
  access: boolean
  active: boolean
  enrollment: string
  setName: React.Dispatch<React.SetStateAction<string>>
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setAccess: React.Dispatch<React.SetStateAction<boolean>>
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  setEnrollment: React.Dispatch<React.SetStateAction<string>>
}

const Inputs: React.FC<IProps> = ({
  name,
  email,
  access,
  active,
  enrollment,
  setName,
  setEmail,
  setAccess,
  setActive,
  setEnrollment,
}) => {
  return (
    <Paper
      containerStyle={{
        width: '100%',
        padding: '1rem',
        backgroundColor: ` ${active ? '#FFF' : '#FFF5F5'}`,
      }}
    >
      <Text pb="0.5rem" fontSize="md" fontWeight="semibold" color="#003b74">
        Informações
      </Text>
      <VStack w="100%" align="flex-start">
        <HStack w="100%" spacing="1rem">
          <VStack w="100%" align="flex-start">
            <Text>Nome Completo</Text>
            <Input
              value={name}
              isInvalid={!name}
              onChange={(e) => setName(e.target.value)}
            />
          </VStack>
          <VStack w="100%" align="flex-start">
            <Text>E-mail</Text>
            <Input
              value={email}
              isInvalid={!email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </VStack>
        </HStack>
        <HStack w="100%" align="flex-end" spacing="1rem">
          <VStack w="100%" align="flex-start">
            <Text>Matrícula</Text>
            <Input
              value={enrollment}
              isInvalid={!enrollment}
              onChange={(e) => setEnrollment(e.target.value)}
            />
          </VStack>
          <HStack w="100%" spacing="1rem">
            <HStack pb="0.5rem" userSelect="none" alignItems="center">
              <Checkbox
                size="lg"
                colorScheme="red"
                isChecked={active}
                onChange={() => setActive(!active)}
              >
                <Text fontSize="1rem">Bloquear Usuário</Text>
              </Checkbox>
            </HStack>
            <HStack pb="0.5rem" userSelect="none" alignItems="center">
              <Checkbox
                size="lg"
                isChecked={access}
                onChange={() => setAccess(!access)}
              >
                <Text fontSize="1rem">Controle de Acessos</Text>
              </Checkbox>
            </HStack>
          </HStack>
        </HStack>
      </VStack>
    </Paper>
  )
}

export default Inputs
