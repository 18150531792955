import React, { useCallback } from 'react'
import { FaTrash as DeleteSvg } from 'react-icons/fa'
import api from 'services/api'
import { IDeleteComponent } from '../types'
import { IMaterials } from '../../types'

export const DeleteComponent: React.FC<IDeleteComponent> = ({
  row,
  setRows,
}) => {
  const onDelete = useCallback(
    (skuToDel: string) => {
      api.delete(`/relativity-sku/${skuToDel}`).then(() => {
        setRows((current: IMaterials[]) => {
          return current.filter((row: IMaterials) => row?.sku !== skuToDel)
        })
      })
    },
    [setRows]
  )

  return (
    <>
      {row.sku === row.captain_code ? (
        ''
      ) : (
        <DeleteSvg onClick={() => onDelete(row.sku)} />
      )}
    </>
  )
}
